.block-push-pleine-largeur {
    position: relative;
    overflow: hidden;

    .inner-content-container {
        text-align: center;

        @include min(breakpoint(md)) {
            max-width: 720px;
            margin: 0 auto;
            padding: 0;
        }

        .title,
        .wrapper-buttons {
            position: relative;
            z-index: 2;
        }

        .title {
            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }

        .wrapper-buttons {
            justify-content: center;
            align-items: initial;
        }
    }

    .b-to-c & {
        background-color: color(primary);
        padding: 40px 0 10px;

        @include min(breakpoint(md)) {
            padding: 75px 0;
        }

        &::before {
            display: none;

            @include min(breakpoint(lg)) {
                content: '';
                aspect-ratio: 179/82;
                display: flex;
                background-image: url($path-svg + 'decoration-push-plane.svg');
                width: 179px;
                position: absolute;
                right: 103px;
                top: 109px;
                z-index: 1;
            }
        }

        &::after {
            content: '';
            width: 100%;
            aspect-ratio: 375/175;
            display: flex;
            background-image: url($path-svg + 'decoration-push-box.svg');
            background-size: contain;
            margin-top: 40px;
            position: relative;
            z-index: 1;

            @include min(breakpoint(md)) {
                margin: 0;
                width: 375px;
                position: absolute;
                left: 0;
                bottom: -40px;
            }
        }

        .pattern {
            position: absolute;

            svg {
                path {
                    fill: rgba(255, 255, 255, 0.40);
                }
            }
        
            &.first {
                display: none;

                @include min(breakpoint(md)) {
                    display: flex;
                    width: 1295px;
                    bottom: -98px;
                    left: -732px;
                }
            }

            &.last {
                width: 1251px;
                left: 50%;
                transform: translateX(-50%);
                top: -995px;

                @include min(breakpoint(md)) {
                    left: unset;
                    transform: unset;
                    right: -705px;
                    top: -815px
                }
            }
        }
    }

    .b-to-b & {
        background-color: color(dark-grey);
        color: color(white);
        padding: 100px 0;

        @include min(breakpoint(md)) {
            padding: 75px 0;
        }

        .pattern {
            position: absolute;
            display: flex;

            svg {
                path {
                    stroke: color(primary);
                }
            }
        
            &.first {
                width: 1300px;
                top: -1203px;
                left: -727px;

                @include min(breakpoint(md)) {
                    width: 1468px;
                    top: -1193px;
                    left: -825px;
                }
            }

            &.last {
                width: 1300px;
                bottom: -1122px;
                right: -734px;

                @include min(breakpoint(md)) {
                    width: 1165px;
                    bottom: unset;
                    top: -746px;
                    right: -785px;
                }
            }
        }
    }
}
