.block-etapes-illustrees {
    .block-inner-content-container {
        .title {
            text-align: center;
            margin-bottom: 40px;

            @include min(breakpoint(md)) {
                margin-bottom: 50px;
            }
        }

        .wrapper-steps {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 87px;

            @include min(breakpoint(lg)) {
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                gap: 60px;
            }

            .step {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 335px;

                @include min(breakpoint(lg)) {
                    max-width: 270px;
                }

                &:not(:last-child) {
                    &::after {
                        content: '';
                        display: flex;
                        width: 17px;
                        height: 59px;
                        background-image: url($path-svg + 'decoration-etapes-illustrees-mobile.svg');
                        position: absolute;
                        top: calc(100% + 10px);
                        left: 50%;
                        transform: translateX(-50%);

                        @include min(breakpoint(lg)) {
                            display: none;
                        }
                    }
                }

                &::before {
                    content: '';
                    display: flex;
                    width: 60%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                }

                &:nth-child(1) {
                    &::before {
                        @include min(breakpoint(lg)) {
                            content: '';
                            max-width: 127px;
                            height: 41px;
                            background-image: url($path-svg + 'decoration-etapes-illustrees-1.svg');
                            top: 50px;
                            left: 100%;
                            transform: translateX(-23%);
                        }
                    }
                }

                &:nth-child(2) {
                    &::before {
                        @include min(breakpoint(lg)) {
                            content: '';
                            max-width: 140px;
                            height: 21px;
                            background-image: url($path-svg + 'decoration-etapes-illustrees-2.svg');
                            top: 50px;
                            left: 100%;
                            transform: translateX(-23%);
                        }
                    }
                }

                &:nth-child(3) {
                    &::before {
                        @include min(breakpoint(lg)) {
                            content: '';
                            max-width: 176px;
                            height: 31px;
                            background-image: url($path-svg + 'decoration-etapes-illustrees-3.svg');
                            top: 50px;
                            left: 100%;
                            transform: translateX(-23%);
                        }
                    }
                }

                .step-image {
                    display: none;

                    @include min(breakpoint(md)) {
                        display: flex;
                        height: 140px;
                        width: auto;
                        margin-bottom: 40px;

                        img {
                            height: 100%;
                            width: auto;
                        }
                    }
                }

                .step-number {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: color(primary);
                    border-radius: 100px;
                    margin-bottom: 20px;
                    font-size: rem(20px);
                    font-weight: 400;
                }

                .step-title {
                    font-size: rem(20px);
                    font-weight: 400;
                    line-height: 1.1;
                    margin-bottom: 10px;
                    text-align: center;
                    letter-spacing: -0.4px;

                    @include min(breakpoint(md)) {
                        margin-bottom: 20px;
                    }
                }

                .step-text {
                    font-size: rem(16px);
                    line-height: 1.6;
                    text-align: center;
                }
            }
        }
    }

    .block-push-ressource {
        margin-bottom: 0;

        .container {
            padding: 0;
        }
    }
}

