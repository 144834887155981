.header-text-image {
    position: relative;
    overflow: hidden;

    &.dark {
        .pattern {    
            svg {                    
                path {
                    fill: unset;
                    stroke: color(primary);
                }
            }
        }
    }

    &.block-spacing {
        padding: 35px 0 50px;
    
        @include min(breakpoint(md)) {
            padding: 85px 0 100px;
        }

    }

    // TYPE TEXTE ONLY
    &.type-text-only {
        .breadcrumb {
            justify-content: center;
        }

        .wrapper-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            max-width: 900px;
            margin: 0 auto;
        }
    }

    // TYPE TEXTE IMAGE
    &.type-text-image {
        .wrapper-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            max-width: 900px;
            margin: 0 auto;

            @include min(breakpoint(lg)) {
                text-align: left;
                align-items: flex-start;
            }
        }
        
        .wrapper-image {
            max-width: 480px;
        }        

        .pattern {
            width: 845px;
            right: -533px;
            bottom: -553px;
        }
    }

    // TYPE TEXTE IMAGE FULL
    &.type-text-image-full {
        .inner-content-container {
            position: relative;
            overflow: hidden;
            padding: 50px 20px;

            @include min(breakpoint(md)) {
                padding-top: 80px;
                padding-bottom: 90px;
            }
        }

        .breadcrumb {
            justify-content: center;
            margin-bottom: 25px;
        }

        .wrapper-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            max-width: 925px;
            margin: 0 auto;
            color: color(white);
        }

        .wrapper-image {
            @include absolute-center-full;
        }

        .pattern {
            z-index: 2;
            width: 807px;
            right: -502px;
            bottom: -526px;
        }
    }

    .breadcrumb {
      justify-content: center;    
      
      @include min(breakpoint(lg)) {
        justify-content: flex-start;
      }
    } 

    .inner-content-container {
        z-index: 1;
        display: flex;
        gap: 30px;
        flex-direction: column;

        @include min(breakpoint(lg)) {
           flex-direction: row-reverse;
           justify-content: space-between;
           align-items: center;
           gap: 50px;
        }
    }

    .wrapper-content {
        position: relative;
        z-index: 3;

        .title {
            &:not(:last-child) {
                margin-bottom: 20px;

                @include min(breakpoint(md)) {
                    margin-bottom: 30px;
                }
            }
        }

        .text {
            font-weight: 300;

            @include min(breakpoint(md)) {
                font-size: rem(20px);
            }
        }

        .wrapper-buttons {
            margin-top: 32px;

            @include min(breakpoint(md)) {
                margin-top: 40px;
            }
        }
    }

    .wrapper-image {
        border-radius: 20px;
        position: relative;
        z-index: 1;
        flex-shrink: 0;
        margin: 0 auto;

        @include min(breakpoint(md)) {
            width: 50%;
        }

        &.image-bigger {
            max-width: 660px;
           
            @include min(breakpoint(lg)) {
                margin-left: -40px;
            }
        }
    }

    .pattern {
        display: none;
        position: absolute;
        width: 982px;
        right: -607px;
        bottom: -639px;

        @include min(breakpoint(md)) {
            display: block;
        }

        svg {                    
            path {
                fill: color(primary);
            }
        }
    }
}
