.block-implantation-onglets {
    overflow-x: clip;
    overflow-y: visible;
    min-height: 550px;

    &.has-dark-grey-bg-color {
        .tab-title {
            box-shadow: unset !important;
        }
    } 

    .inner-content-container {
        position: relative;
    }

    .heading {
        margin-bottom: 30px;
        max-width: 500px;

        @include min(breakpoint(lg)) {
            margin-bottom: 40px;
        }
    }

    .wrapper-tabs {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include min(breakpoint(lg)) {
            max-width: 500px;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .tab {
            width: 100%;

            @include min(breakpoint(lg)) {
                width: calc(50% - 10px);
            }
        }

        .tab-title {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            padding: 5px 30px 5px 5px;
            transition: color $regular-transition, background-color $regular-transition ;
            cursor: pointer;
            border: 0;
            width: 100%;
            text-align: left;
            background-color: color(white20);
            color: currentColor;
            border-radius: 10px;
            box-shadow: 0px 4px 24px 0px #E5E5E5;

            @include min(breakpoint(lg)) {
                border-radius: 20px;
                padding: 15px;
                position: initial;
            }

            &::after {
                content: '';
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                border-right: 1px solid color(black);
                border-bottom: 1px solid color(black);
                display: block;
                width: 6px;
                height: 6px;

                @include min(breakpoint(lg)) {
                    display: none;
                }
            }

            &:hover,
            &.active {
                background-color: color(primary);
                color: color(black);
            }

            .tab-title-image {
                width: 50px;
                height: 50px;
                border-radius: 10px;

                @include min(breakpoint(lg)) {
                    width: 60px;
                    height: 60px;
                }
            }
        }

        .tab-content {
            display: none;
            transition: transform $regular-transition;
            pointer-events: none;

            @include min(breakpoint(lg)) {
                position: absolute;
                width: 50%;
                max-width: 670px;
                top: 0;
                transform: translateX(50px);
                right: 0;
                display: block;
                opacity: 0;
            }

            &.active {
                display: block;
                pointer-events: auto;
                padding: 40px 0 20px;

                @include min(breakpoint(lg)) {
                    padding: 0;
                    opacity: 1;
                    transform: translateX(0);
                }
            }

            .wrapper-image {
                border-radius: 20px;
                aspect-ratio: 670/383;
                width: 100%;
                display: none;
                margin-bottom: 30px;

                @include min(breakpoint(lg)) {
                    display: flex;
                }
            }

            .wrapper-content {
                display: flex;
                flex-direction: column;
                gap: 40px;

                @include min(breakpoint(lg)) {
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 30px;
                }

                .wrapper-content-col {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    @include min(breakpoint(lg)) {
                        width: 50%;
                        max-width: 300px;
                    }

                    .content {
                        display: flex;
                        gap: 10px;
                        font-size: rem(18px);
                        line-height: 1.3;

                        .icon {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-shrink: 0;
                            margin-top: 1px;

                            svg {
                                @include color-svg(currentColor);
                            }
                        }

                        .phone-wrapper {
                            text-decoration: none;
                        }

                        a {
                            &:hover {
                                color: color(grey-hover);
                            }
                        }
                    }
                }
            }
        }
    }
}
