.block-implantation-liste-defilement {
    color: color(white);
    overflow: hidden;
    position: relative;

    .inner-content-container {
        .container {
            max-width: 800px;
            text-align: center;
            position: relative;
            z-index: 1;

            .title {
                margin-bottom: 20px;

                @include min(breakpoint(md)) {
                    margin-bottom: 25px;
                }
            }

            .text {
                line-height: 2;

                @include min(breakpoint(md)) {
                    line-height: 1.8;
                }
            }

            .wrapper-buttons {
                align-items: center;
                margin-top: 20px;

                @include min(breakpoint(md)) {
                    justify-content: center;
                    margin-top: 35px;
                }
            }
        }

        .scroll-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-top: 40px;
            z-index: 1;

            @include min(breakpoint(md)) {
                margin-top: 50px;
            }
    
            &:hover {
                .implantations {
                    animation-play-state: paused;
                }
            }
        }

        .implantations {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            
            &.implantations-slide {
                animation: var(--animation-slide-timing, 10s) slide infinite linear;
            }

            &.implantations2-slide {
                animation: var(--animation-slide-timing, 10s) slide-reverse infinite linear;
            }
    
            .implantation {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                margin: 0 15px;
                gap: 35px;
    
                @include min(breakpoint(md)) {
                    margin: 0 50px;
                }

                .image {
                    display: flex;
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;

                    @include min(breakpoint(md)) {
                        width: 120px;
                        height: 120px;
                        border-radius: 20px;
                    }
                }
            }
        }    
    }
}