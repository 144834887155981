.header-accueil-b-to-b {
    position: relative;
    color: color(white);
    overflow: hidden;

    &.block-spacing {
        margin: 0;
        padding: 80px 0;

        @include min(breakpoint(md)) {
            padding: 80px 0 95px;
        }
    }

    .inner-content-container {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 25px;

        @include min(breakpoint(md)) {
           flex-direction: row;
           justify-content: space-between;
           padding-bottom: 100px;
        }

        .header-accueil-content {
            @include min(breakpoint(md)) {
                width: 55%;
                max-width: 645px;
            }

            .switch-context {
                margin-bottom: 25px;
                display: flex;
                align-items: center;
                width: fit-content;
                height: 40px;
                background-color: color(white20);
                border-radius: 50px;
                padding: 5px;

                @include min(breakpoint(md)) {
                    margin-bottom: 40px;
                }

                .switch-link {
                    padding: 0 13px;
                    border-radius: 50px;
                    height: 30px;
                    text-align: center;
                    text-decoration: none;
                    font-size: rem(16px);
                    display: flex;
                    align-items: center;
                    position: relative;
                    z-index: 1;

                    &.switch-link--active {
                        color: color(black);
                    }

                    &.switch-link--active-ready {
                        background-color: color(primary);
                    }
                }

                .switch-context-active-indicator {
                    width: 0;
                    height: 30px;
                    background-color: color(primary);
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    border-radius: 100px;

                    &.switch-context-active-indicator-ready {
                        transition: width $regular-transition, transform $regular-transition;
                    }
                }
            }

            .title {
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 10px;

                    @include min(breakpoint(md)) {
                        margin-bottom: 20px;
                    }
                }

                .title-decoration {
                    display: flex;
                    position: absolute;
                    right: -90px;
                    top: -30px;
                }
            }
    
            .text {    
                @include min(breakpoint(md)) {
                    font-size: rem(20px);
                    max-width: calc(100% - 110px);
                }
            }

            .wrapper-buttons {
                margin-top: 30px;
                align-items: center;

                @include min(breakpoint(md)) {
                    margin-top: 40px;
                    align-items: flex-start
                }
            }

            .wrapper-list {
                @extend %reset-ul;
    
                display: flex;
                flex-direction: column;
                gap: 13px;
                margin-top: 30px;
    
                @include min(breakpoint(md)) {
                    position: absolute;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 10px 10px;
                    padding-top: 50px;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                }
    
                .list-item {
                    display: flex;
                    gap: 10px;
                    align-items: center;
    
                    &:not(:last-child) {
                        &::after {
                            display: flex;
                            width: 7px;
                            height: 7px;
                            background-color: currentColor;
                            border-radius: 100px;
        
                            @include min(breakpoint(md)) {
                                content: '';
                            }
                        }
                    }
    
                    .list-item-icon {
                        display: flex;
                        flex-shrink: 0;
                        width: 24px;
                        height: 24px;
                    }
    
                    .list-item-text {
                        font-size: rem(16px);
    
                        @include min(breakpoint(md)) {
                            font-size: rem(18px);
                        }
                    }
                }
            }
        }

        .header-accueil-image {
            position: relative;
            width: 100%;
            aspect-ratio: 335/240;
            margin-top: 30px;
    
            @include min(breakpoint(md)) {
                width: 50%;
                max-width: 595px;
                aspect-ratio: 595/560;
                margin-top: 0;

            }

            .image1 {
                border-radius: 20px;
                position: absolute;
                left: 0;
                bottom: 30px;
                z-index: 2;
                aspect-ratio: 300/220;
                max-width: 300px;
                box-shadow: 20px 10px 80px 0px rgba(0, 0, 0, 0.35);

                video {
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }
            }

            .image2 {
                position: relative;
                z-index: 1;
                border-radius: 20px;
                width: 100%;
                height: 100%;
                aspect-ratio: 335/240;

                @include min(breakpoint(md)) {
                    max-width: 420px;
                    aspect-ratio: 420/560;
                    width: 70%;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }

            .pattern {
                position: absolute;
                width: 274px;
                top: 50%;
                right: -190px;
                transform: translateY(-50%);

                @include min(breakpoint(md)) {
                   width: 712px;
                   right: -555px;
                }

                svg {                    
                    path {
                        stroke: color(primary);
                    }
                }
            }
        }
    }
}
