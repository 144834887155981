.block-derniers-posts {
    overflow-x: clip;
    overflow-y: visible;

    .inner-content-container {
        position: relative;

        & > .title {
            margin-bottom: 30px;
            text-align: center;

            @include min(breakpoint(md)) {
                text-align: left;
                margin-bottom: 45px;
                max-width: calc(100% - 200px);
            }
        }

        .wrapper-articles {
            overflow: visible;

            .swiper-wrapper {
                @include min(breakpoint(md)) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 40px 30px;
                }

                @include min(breakpoint(xl)) {
                    grid-template-columns: repeat(4, 1fr);
                }
            }

            .swiper-slide {
                width: 300px;

                @include min(breakpoint(md)) {
                    width: 100%;

                    &:first-child {
                        grid-row-start: 1;
                        grid-row-end: 3;
                        grid-column-start: 1;
                        grid-column-end: 3;

                    }
                }
            }
        }

        .wrapper-buttons {
            align-items: center;
            margin-top: 30px;

            @include min(breakpoint(md)) {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 0;
            }
        }
    }
}
