.block-liste-logos {
	overflow-x: clip;
    overflow-y: visible;

	.text {
		text-align: center;
		font-size: rem(16px);
		margin-bottom: 40px;

		@include min(breakpoint(md)) {
			font-size: rem(20px);
		}

		strong {
			display: inline-flex;
			font-weight: 300;
			padding: 2px 10px;
			background-color: color(primary);
			border-radius: 50px;
		}
	}

	.scroll-container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: fit-content;
		margin: 0 auto;

		&:hover {
			.logos-wrapper {
				animation-play-state: paused;
			}
		}
	}

	.logos-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;

		&.logos-wrapper-slide {
			animation: var(--animation-slide-timing, 10s) slide infinite linear;
		}

		.logo {
			display: flex;
			justify-content: center;
			max-width: 110px;
			flex-shrink: 0;
			margin: 0 15px;

			@include min(breakpoint(md)) {
				max-width: 130px;
				margin: 0 25px;
			}

			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
}


