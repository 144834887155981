.main-header {
    display: flex;
    align-items: center;
    position: fixed;
    top: var(--admin-bar-height);
    left: 0;
    z-index: 1000;
    width: 100%;
    height: $height-nav-primary-mobile;
    padding: 10px 0;
    transition: $transition;

    --color-logo: #{color(dark-grey)};
    --bg-color-menu: #{color(white)};
    --color-text-menu: #{color(black)};
    --bg-color-text-btn-link: #{color(black)};
    --color-text-btn-link: #{color(white)};
    --pattern-nav-fill: #{color(primary)};
    --pattern-nav-stroke: transparent;
    --bottom-submenu-desktop: 42px;

    &.b-to-b {
        --color-logo: #{color(white)};
        --bg-color-menu: #{color(dark-grey)};
        --color-text-menu: #{color(white)};
        --bg-color-text-btn-link: #{color(white)};
        --color-text-btn-link: #{color(black)};
        --pattern-nav-fill: transparent;
        --pattern-nav-stroke: #{color(primary)};
    }

    @include min(breakpoint(nav)) {
        border: 0;
        padding: 0;
        height: $height-nav-primary;
        top: calc(var(--admin-bar-height));
    }

    .main-header-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        font-size: rem(16px);

        .header-nav-link {
            color: var(--color-text-menu);
            font-weight: 400;
            text-decoration: none;
            transition: $transition;

            &:not(.display-button) {
                &:hover {
                    opacity: .5;
                }
            }

            &.display-button {
                min-height: 40px;
                background-color: transparent;
                border: 1px solid var(--color-text-menu);
                font-size: rem(16px);
                padding: 5px 13px;
                border-radius: 100px;
                color: var(--color-text-menu);
                position: relative;
                overflow: hidden;
                transition: border-color $regular-transition, background-color $regular-transition;
                display: flex;
                align-items: center;
                justify-content: center !important;

                &:hover {
                    color: color(black);
                    background-color: color(primary);
                    border-color: color(primary);
                }
            }
        }

        .main-header-logo {
            display: flex;
            flex-shrink: 0;
            width: 200px;
            height: 46px;
            position: relative;
            z-index: 3;

            @include max(breakpoint(nav)) {
                width: 117px;
                height: 27px;
            }

            svg {
                width: 100%;
                height: auto;

                .changing-path {
                    fill: var(--color-logo);
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .main-header-left {
            display: flex;
            align-items: center;
            gap: 10px;

            @include max(breakpoint(nav)) {
                gap: 14px;
            }

            .burger-btn,
            .back-button-menu {
                background: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;
                color: inherit;
                appearance: button;
                cursor: pointer;
                position: relative;
                z-index: 3;

                @include min(breakpoint(nav)) {
                    display: none;
                }
            }

            .burger-btn {
                width: 32px;
                height: 32px;
                padding: 0;
                position: relative;

                span,
                &::before,
                &::after {
                    content: '';
                    display: block;
                    width: 28px;
                    height: 1.5px;
                    background-color: var(--color-text-menu);
                    position: absolute;
                    border-radius: 20px;
                    transition: $transition;
                }

                span {
                    transform: translate(-50%, -50%);
                    top: 50%;
                    left: 50%;
                }

                &::before {
                    top: 5px;
                    left: 50%;
                    transform: translate(-50%, 0);
                }

                &::after {
                    bottom: 5px;
                    left: 50%;
                    transform: translate(-50%, 0);
                }

                .main-header-menu-is-active & {
                    span {
                        opacity: 0;
                    }

                    &::before {
                        transform: translate(-50%, -50%) rotate(45deg);
                        top: 50%;
                        left: 50%;
                    }
    
                    &::after {
                        transform: translate(-50%, -50%) rotate(-45deg);
                        top: 50%;
                        left: 50%;
                    }
                }
            }

            .main-header-menu {
                display: flex;
                align-items: center;
                gap: 30px;

                .header-nav-list {
                    @extend %reset-ul;

                    display: flex;
                    align-items: center;
                    gap: 20px;

                    .header-nav-element {
                        cursor: pointer;

                        .header-nav-link {
                            cursor: pointer;
                        }

                        &:hover {
                            .submenu-wrapper {
                                @include min(breakpoint(nav)) {
                                    display: block !important;
                                    opacity: 1 !important;
                                    visibility: visible !important;
                                }

                                .submenu-inner-wrapper {
                                    pointer-events: auto !important;
                                }
                            }
                        }

                        &.has-submenu {
                            .header-nav-link {
                                display: flex;
                                align-items: center;
                                gap: 7px;
                                appearance: none;
                                background: transparent;
                                border: 0;
                                padding: 0;
                                height: 20px;

                                .arrow-down-icon {
                                    svg {
                                        @include color-svg(var(--color-text-menu));

                                        path {
                                            transition: $transition;
                                        }
                                    }
                                }

                                &.active {
                                    & + .submenu-wrapper {
                                        @include min(breakpoint(nav)) {
                                            display: block !important;
                                            opacity: 1 !important;
                                            visibility: visible !important;
                                        }

                                        .submenu-inner-wrapper {
                                            pointer-events: auto !important;
                                        }
                                    }
                                }
                            }

                            .submenu-wrapper {
                                // SUBMENU DESKTOP
                                @include min(breakpoint(nav)) {
                                    max-width: 1240px;
                                    position: absolute;
                                    width: 100%;
                                    bottom: var(--bottom-submenu-desktop);
                                    left: 50%;
                                    cursor: auto;
                                    pointer-events: none;
                                    transition: opacity $regular-transition, visibility $regular-transition;
                                    opacity: 0;
                                    visibility: hidden;
                                    transform: translateX(-50%) translateY(100%) !important;

                                    &:before {
                                        display: block;
                                        pointer-events: auto;
                                        width: 100%;
                                        height: 44px;
                                        content: "";
                                    }

                                    .submenu-inner-container {
                                        margin: 0 auto;
                                    }

                                    .submenu-inner-wrapper {
                                        display: flex;
                                        width: fit-content;
                                        max-width: 100%;
                                        margin: 0 auto;
                                        max-height: calc(100vh - (20px + $height-nav-primary));
                                        pointer-events: none;
                                        background-color: color(white);
                                        box-shadow: 0px 7px 50px rgba(6, 18, 31, 0.07);
                                        border-radius: 20px;

                                        .back-button-menu {
                                            display: none;
                                        }

                                        .col {
                                            &.col-simple {
                                                padding: 30px;
                                                display: grid;
                                                grid-template-columns: 1fr 1fr;
                                                column-gap: 20px;

                                                @include max(breakpoint(xl)) {
                                                    width: 100%;
                                                }

                                                .submenu-nav-link {
                                                    display: flex;
                                                    flex-direction: column;
                                                    max-width: 320px;
                                                    padding: 20px;
                                                    text-decoration: none;
                                                    gap: 10px;
                                                    color: color(black);
                                                    cursor: pointer;

                                                    &:hover {
                                                        color: color(grey-hover);
                                                    }

                                                    .submenu-nav-link-title {
                                                        font-size: rem(18px);
                                                        line-height: 1.1; 
                                                    }

                                                    .submenu-nav-link-text {
                                                        font-size: rem(16px);
                                                        line-height: 1.4; 
                                                        font-weight: 200;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .col-push {
                                    width: 340px;
                                    margin: 0 auto;
                                    flex-shrink: 0;
                                    overflow: hidden;
                                    border-radius: 20px;
                                    border: 5px solid color(white);

                                    .push-link {
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                        justify-content: space-between;
                                        gap: 20px;
                                        padding: 30px;
                                        text-decoration: none;
                                        position: relative;
                                        overflow: hidden;
                                        border-radius: 20px;

                                        &:hover {
                                            .wrapper-image {
                                                img {
                                                    scale: 1.1;
                                                }
                                            }
                                            
                                            .title {
                                                color: color(primary);
                                            }
                                        }
                                    }

                                    .wrapper-image {
                                        @include absolute-center-full;
                                    }

                                    .icon {
                                        position: relative;
                                        z-index: 1;
                                        width: 50px;
                                        height: 50px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background-color: color(white);
                                        border-radius: 10px;
                                    }

                                    .title {
                                        position: relative;
                                        z-index: 1;
                                        font-size: rem(32px);
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 1;
                                        color: color(white);
                                        transition: color $regular-transition;
                                        display: flex;
                                        flex-direction: column;
                                        gap: 15px;
                                    }
                                }
                            }
                        }
                    }
                }

                .social-networks-wrapper {
                    display: none;
                }

                .pattern {
                    display: none;
                }

                @include max(breakpoint(nav)) {
                    position: absolute;
                    inset: 0;
                    width: 100%;
                    height: calc(var(--vh, 100vh) - var(--admin-bar-height));
                    background-color: var(--bg-color-menu);
                    left: -100%;
                    transition: $transition;
                    flex-direction: column;
                    padding: 0;
                    gap: 0;
                    align-items: flex-start;
                    overflow: hidden;

                    &--active {
                        left: 0;
                    }

                    .header-nav-list {
                        width: 100%;
                        margin: 0 auto;
                        flex-direction: column;
                        align-items: unset;
                        gap: 30px;
                        flex-grow: 1;
                        overflow: scroll;
                        padding: 20px 20px;
                        padding-top: calc(30px + #{$height-nav-primary-mobile});
                        z-index: 1;

                        .header-nav-element {
                            .header-nav-link {
                                font-size: rem(16px);
                                width: 100%;
                                justify-content: space-between;

                                .arrow-down-icon {
                                    transform: rotate(-90deg);

                                    svg {
                                        @include color-svg(var(--color-text-menu));
                                    }
                                }

                                &.display-button {
                                    margin-top: 30px;
                                }
                            }

                            &.has-submenu--active {
                                .submenu-wrapper {
                                    left: 0;
                                }
                            }
                        }
                    }

                    .submenu-wrapper {
                        position: absolute;
                        width: 100%;
                        left: -100%;
                        top: 0;
                        padding-top: calc(30px + #{$height-nav-primary-mobile});
                        background-color: var(--bg-color-menu);
                        height: calc(var(--vh, 100vh) - var(--admin-bar-height));
                        overflow: auto;
                        transition: left $regular-transition;
                        z-index: 2;

                        .submenu-inner-container {
                            overflow: auto;
                            height: calc(100% - 80px);
                            padding: 0 20px 20px;
                        }

                        .submenu-inner-wrapper {

                            .back-button-menu {
                                display: flex;
                                gap: 13px;
                                align-items: center;
                                width: fit-content;
                                font-size: rem(25px);
                                padding-left: 0;
                                margin: 10px 0 30px;
                                transition: color $regular-transition;
                                color: var(--color-text-menu);

                                .icon-arrow-left {
                                    display: flex;
                                    align-items: center;

                                    svg {
                                        @include color-svg(var(--color-text-menu));
                                    }
                                }

                                @media(hover: hover) {
                                    &:hover {
                                        color: color(grey-hover);

                                        svg {
                                            @include color-svg(color(grey-hover));
                                        }
                                    }
                                }
                            }

                            .col-simple {
                                .submenu-item {
                                    &:not(:last-child) {
                                        margin-bottom: 25px;
                                    }

                                    .submenu-nav-link {
                                        font-size: rem(16px);
                                        font-weight: 400;
                                        text-decoration: none;
                                        color: var(--color-text-menu);

                                        .submenu-nav-link-text {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            .col-push {
                                display: none;
                            }
                        }
                    }

                    .social-networks-wrapper {
                        list-style: none;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        padding: 20px 20px 30px;
                        gap: 10px;
                        z-index: 1;

                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 30px;
                            height: 30px;

                            svg {
                                @include color-svg(var(--color-text-menu));
                            }

                            &:hover {
                                svg {
                                    @include color-svg(color(primary));
                                }
                            }
                        }
                    }

                    .pattern {
                        position: absolute;
                        display: flex;
                        width: 475px;
                        aspect-ratio: 1/1;
                        bottom: -55px;
                        right: -280px;

                        svg {
                            width: 100%;

                            path {
                                fill: var(--pattern-nav-fill);
                                stroke: var(--pattern-nav-stroke);
                            }
                        }
                    }
                }
            }
        }

        .main-header-right {
            display: flex;
            align-items: center;
            gap: 15px;
            position: relative;
            z-index: 3;

            .button-link {
                display: flex;
                align-items: center;
                min-height: 40px;
                background-color: var(--bg-color-text-btn-link);
                font-size: rem(16px);
                padding: 5px 13px;
                border-radius: 100px;
                color: var(--color-text-btn-link);
                position: relative;
                overflow: hidden;
                transition: color $regular-transition, background-color $regular-transition;
                text-decoration: none;
                text-align: center;

                @include max(breakpoint(nav)) {
                    min-height: 28px;
                    padding: 5px 10px;
                    font-size: rem(12px);
                }

                &:hover {
                    color: color(black);
                    background-color: color(primary);
                }
            }
        }
    }

    &.main-header-sticky {
        --bottom-submenu-desktop: 32px;

        height: $height-nav-primary-sticky;
        background-color: var(--bg-color-menu);
        box-shadow: 0px 2px 50px 0px rgba(0, 0, 0, 0.08);
        top: 0;

        @include min(breakpoint(nav)) {
            top: var(--admin-bar-height);
        }
    }

    .header-nav-link {
        .header-nav-element.has-submenu {
            pointer-events: none !important;
        }
    }

    &.header-mobile {
        .header-nav-element.has-submenu {
            .header-nav-link {
                pointer-events: all !important;
            }
        }
    }
}

.wrapper_language_switcher {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    z-index: 1;

    .wpml-ls-current-language:hover > a {
        &:hover {
            color: currentColor;
        }
    }

    .wpml-ls-legacy-dropdown-click {
        width: auto;

        a {
            display: inline-block;
            text-decoration: none;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            background-color: transparent !important;
            padding: 0 17px 0 0;
            color: color(white) !important;
            transition: $transition;
            border: 0;

            &.wpml-ls-item-toggle {
                display: flex;

                &::after {
                    // plus facile que d'écrire des tonnes d'overrules !
                    display: none;
                }

                &::before {
                    content: "";
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    margin-left: 10px;
                    transition: $transition;
                    border-left: 2px solid #fff;
                    border-bottom: 2px solid #fff;
                    background-repeat: no-repeat;
                    transform: rotate(-45deg) translateY(-50%);
                    border-radius: 1px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                }
            }
        }

        .wpml-ls-sub-menu {
            border: 0;
            border-radius: 3px;
            background-color: color(white);
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
            padding: 0 4px;
            margin-top: 5px;

            .wpml-ls-link {
                color: color(black) !important;
                padding: 0;
                transition: none;
            }
        }

        .wpml-ls-item-toggle {
            border: 0;
        }
    }
}
