.header-text-liste-page {
    position: relative;
    overflow: hidden;

    &.block-spacing {
        padding: 35px 0 50px;
    
        @include min(breakpoint(md)) {
            padding: 85px 0 110px;
        }
    }

    .breadcrumb {
      justify-content: center;
    } 

    .wrapper-content {
        max-width: 900px;
        margin: 0 auto;
        text-align: center;

        &:not(:last-child) {
            margin-bottom: 30px;

            @include min(breakpoint(md)) {
                margin-bottom: 70px;
            }
        }

        .title {
            &:not(:last-child) {
                margin-bottom: 20px;

                @include min(breakpoint(md)) {
                    margin-bottom: 30px;
                }
            }
        }

        .text {
            font-weight: 300;

            @include min(breakpoint(md)) {
                font-size: rem(20px);
            }
        }
    }

    .wrapper-vignettes {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        @include min(breakpoint(md)) {
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            gap: 45px 30px;
        }

        &.wrapper-vignettes-4 {
            @include min(breakpoint(md)) {
                flex-direction: row;
                justify-content: center;
                gap: 45px 20px;
            }

            .vignette {
                @include min(breakpoint(md)) {
                    aspect-ratio: 400/400;
                    width: calc((100% / 2) - 15px);
                }
    
                @include min(breakpoint(lg)) {
                    aspect-ratio: 300/400;
                    width: calc((100% / 4) - 15px);
                }

                .vignette-title {
                    @include min(breakpoint(lg)) {
                        font-size: rem(25px);
                    }
                }
            }
        }

        .vignette {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            gap: 20px;
            aspect-ratio: 335/300;
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            text-decoration: none;
            padding: 30px;

            @include min(breakpoint(md)) {
                aspect-ratio: 400/400;
                width: calc((100% / 2) - 15px);
            }

            @include min(breakpoint(lg)) {
                width: calc((100% / 3) - 20px);
            }

            &:hover {
                .vignette-image {
                    img {
                        transform: scale(1.1);
                    }
                }

                .vignette-title {
                    color: color(primary);
                }
            
            }

            .vignette-image {
                @include absolute-center-full;

                &::after {
                    @include absolute-center-full;
                    border-radius: 20px;
                    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 20.82%, rgba(0, 0, 0, 0.00) 70.02%);

                    content: '';
                }
            }

            .vignette-title {
                position: relative;
                z-index: 1;
                transition: color $regular-transition;
                display: flex;
                flex-direction: column;
                gap: 25px;
                color: color(white);

                .arrow {
                    display: flex;
                }
            }

            .vignette-icon {
                position: relative;
                z-index: 1;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: color(white);
                border-radius: 10px;
            }
        }
    }
}
