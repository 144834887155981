.block-carousel-citations {
    overflow: hidden;
    position: relative;
    
    @include min(breakpoint(md)) {
        &:after {
            content: '';
            background-image: url($path-svg + 'decoration-citation.svg');
            background-repeat: no-repeat;
            position: absolute;
            top: 20px;
            right: 0;
            width: 416px;
            height: 122px;
            z-index: 1;
        }
    }

    .inner-content-container {
        position: relative;

        .carousel {
            .swiper-wrapper {
                .carousel-slide {
                    position: static;
                    height: 0;
                    opacity: 0;
                    pointer-events: none;
                    overflow: hidden;
                    transition: opacity $regular-transition, height $regular-transition;

                    &.swiper-slide-active {
                        height: auto;
                        opacity: 1;
                        pointer-events: auto;
                    }

                    .wrapper-content {
                        position: relative;
                        color: color(white);
                        background-color: color(dark-grey);
                        display: flex;
                        flex-direction: column;
                        gap: 40px;

                        .text {
                            font-size: rem(25px);
                            font-weight: 400;
                            line-height: 1.7;
                            letter-spacing: -0.75px;

                            @include min(breakpoint(md)) {
                                font-size: rem(32px);
                                letter-spacing: -0.9px;
                            }
                        }

                        .author {
                            display: flex;
                            align-items: center;
                            gap: 20px;

                            .author-image {
                                width: 70px;
                                height: 70px;
                                border-radius: 100px;
                                overflow: hidden;
                                flex-shrink: 0;
                            }

                            .author-name {
                                font-size: rem(16px);
                                font-weight: 600;
                                display: flex;
                                flex-direction: column;
                                gap: 0px;

                                .description {
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                }
            }
        }

        .carousel-citations-pagination {
            display: block;

            @include min(breakpoint(md)) {
                display: none;
            }
        }

        .carousel-citations-navigation {
            display: none;

            @include min(breakpoint(md)) {
                display: flex;
                align-items: center;
                gap: 10px;
                position: absolute;
                bottom: 10px;
                right: 0;
                z-index: 2;
            }

            .button {
                width: 60px;
                height: 40px;
                background-color: color(primary);
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;
                border-radius: 100px;
                box-shadow: 0px 7px 50px rgba(6,18,31,.07);
                cursor: pointer;
                transition: background-color $regular-transition;

                svg {
                    width: 17px;
                    height: 17px;
                    @include color-svg(color(black));
                }

                &.button-prev {
                    svg {
                        transform: scaleX(-1);
                    }
                }

                &:hover {
                    background-color: color(white);
                }

                &.swiper-button-disabled {
                    opacity: 0;
                }
            }
        }
    }
}
