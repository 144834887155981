.maillage-posts-similaires {
    overflow-x: clip;
    overflow-y: visible;

    .inner-content-container {
        max-width: 1020px;
        margin: 0 auto;
        position: relative;

        & > .title {
            margin-bottom: 30px;
            text-align: center;

            @include min(breakpoint(md)) {
                text-align: left;
                margin-bottom: 45px;
                max-width: calc(100% - 200px);
            }
        }

        .wrapper-articles {
            overflow: visible;

            .swiper-slide {
                width: 300px;

                @include min(breakpoint(md)) {
                    width: 320px;
                }
            }
        }

        .wrapper-buttons {
            align-items: center;
            margin-top: 30px;

            @include min(breakpoint(md)) {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 0;
            }
        }
    }
}
