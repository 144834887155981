
.formulaire {
    p {
        margin: 0;
    }

    //reset
    input, select, textarea {
        appearance: none;
        border: 0;
        background-color: transparent;
        padding: 0;
        max-width: 100%;
        width: 100%;
    }

    .row {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    
        &.row-col-2 {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 20px;

            @include min(breakpoint(md)) {
                gap: 32px;
                flex-direction: row;

                & > div {
                    width: calc(50% - 16px);
                }
            }
        }

        &.submit {
            text-align: right;
        }
    }

    .field {
        width: 100%;

        & > label {
            font-size: rem(11px);
            font-weight: 400;
            line-height: 1;
            margin-bottom: 10px;
            text-transform: uppercase;
            display: inline-block;

            .required {
                color: #c72121;
                font-size: 0.6em;
                margin-bottom: 3px;
            }
        }

        .field-input {
            height: 50px;
            border-radius: 8px;
            border: solid 1px color(light-grey);
            padding: 0 15px;
            display: flex;
            align-items: center;
            gap: 10px;

            .icon {
                display: flex;

                svg {
                    @include color-svg(color(black50));
                }
            }

            &.field-input-date {
                position: relative;

                .icon {
                    position: absolute;
                    top: 50%;
                    left: 13px;
                    transform: translateY(-50%);
                }
            }
        }

        input:not([type="checkbox"]), select, textarea {
            background-color: color(white);
            font-size: rem(18px);
            font-weight: 300;

            &::placeholder {
                color: color(black50);
            }

            &[aria-invalid="true"] {
                border-color: color(red);
            }

            &:focus {
                outline: none;
            }

            /* &:-internal-autofill-selected {
                box-shadow: 0 0 0 30px rgb(133, 159, 248) inset !important;
                -webkit-text-fill-color: white !important;
                border-color: rgb(154, 177, 255);
            } */
        }

        select {
            background-image: url($path-svg + 'arrow-down-select.svg');
            background-repeat: no-repeat;
            background-position: center right 15px;
            padding-right: 50px;
        }

        input[type=date] {
            -webkit-appearance: textfield;
            -moz-appearance: textfield;
            min-width: 80%;
            text-align: left;
            padding-left: 27px;

            &::-webkit-datetime-edit,
            &::-webkit-date-and-time-value {
                color: color(black);
                text-align: left;
            }

            &::-webkit-calendar-picker-indicator {
                opacity: .5;
                background-image: none;
                position: absolute;
                top: 50%;
                left: 13px;
                transform: translateY(-50%);
                z-index: 1;
            }
        }

        textarea {
            padding: 15px;
            height: auto;
            min-height: 160px;
        }

        .field-input-radio-group {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;

            .field-input-radio {
                position: relative;

                input[type=radio] {
                    position: absolute;
                    width: 0;
                    height: 0;
                }

                .label-radio {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 65px;
                    height: 50px;
                    border-radius: 8px;
                    border: 1px solid color(light-grey);
                    color: color(dark-grey);
                    font-size: rem(18px);
                    cursor: pointer;
                    transition: border-color $regular-transition, background-color $regular-transition, color $regular-transition;
    
                    &:hover {
                        border-color: color(primary);
                        background-color: color(primary);
                        color: color(black);
                    }
                }

                input[type=radio]:checked + .label-radio {
                    border-color: color(primary);
                    background-color: color(primary);
                    color: color(black);
                }
            }

            
        }
    }

    .optin {
        font-size: rem(14px);
        color: color(dark-grey);

        p {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        a {
            font-weight: 500;
        }
    }

    .wrapper-submit {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 32px;

        @include min(breakpoint(md)) {
            margin-top: 56px;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

    }

    .screen-reader-response {
        display: none;
    }
}

// OVERULES HUBSPOT
.hbspt-form {
    .hs-form-field {
        margin-bottom: 20px !important;

        @include min(breakpoint(md)) {
            margin-bottom: 35px !important;
        }

        label {
            font-family: $mundial !important; 
            font-size: rem(10px) !important;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: color(black) !important;
            margin-bottom: 10px !important;

            .hs-form-required {
                color: color(black) !important;
            }

            &.hs-error-msg {
                text-transform: initial;
                color: color(red) !important;
                margin-top: 5px;
                margin-bottom: 0 !important;
            }
        }

        .hs-input:not([type=checkbox]):not([type=radio]) {
            background-color: color(white);
            border-radius: 8px;
            border: 1px solid #D7D4CF;
            font-family: $mundial !important; 
            font-size: rem(16px) !important;
            font-weight: 300;
            min-height: 45px !important;
            padding: 0 20px;

            @include min(breakpoint(md)) {
                min-height: 50px !important;
                font-size: rem(18px) !important;
            }

            &::placeholder {
                color: color(black50) !important;
            }

            &.hs-fieldtype-textarea {
                min-height: 130px !important;
                padding-top: 20px;
                padding-bottom: 20px;
            }

            &[type=file] {
                &::-webkit-file-upload-button {
                    margin-top: 12px;
                    appearance: none;
                    background-color: transparent;
                    background-image: url($path-svg + 'icon-piece-jointe.svg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    border: 0;
                    text-indent: -9999px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        select.hs-input {
            padding: 0 12px;
            appearance: none;
            background-image: url($path-svg + 'arrow-down.svg');
            background-repeat: no-repeat;
            background-position: center right 20px;
        }

        .inputs-list {
            display: flex;
            align-items: center;
            gap: 30px;

            .hs-form-radio {
                display: flex;
                width: fit-content;

                .hs-form-radio-display {
                    display: flex;
                    width: fit-content;
                    align-items: center;
                    font-size: rem(18px) !important;
                    text-transform: initial;
                    font-weight: 300;
                    color: color(black50) !important;
                    gap: 8px;

                    input[type=radio] {
                        appearance: none;
                        box-shadow: 0px 0px 0px 1px color(black50);
                        font-size: 3em;
                        width: 15px;
                        height: 15px;
                        background-color: color(white);
                        border: 3px solid color(white);
                        border-radius: 100px;
                    }

                    input:checked {
                        background-color: color(black);
                        box-shadow: 0px 0px 0px 1px color(black);
                    }

                    span {
                        font-size: rem(18px);
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .hs-submit {
        .actions {
            display: flex;
            justify-content: flex-end;

            .hs-button {
                display: inline-flex;
                align-items: center;
                gap: 10px;
                justify-content: center;
                transition-duration: $regular-transition-duration;
                transition-property: color, background-color, border-color, gap, transform;
                transition-timing-function: ease-out;
                background-color: color(black);
                border: 1px solid transparent;
                border-color: color(black);
                cursor: pointer;
                font-family: $mundial;
                font-size: rem(16px);
                font-weight: 400;
                line-height: 1;
                color: color(white);
                text-decoration: none;
                border-radius: 100px;
                height: 50px;

                @include min(breakpoint(md)) {
                    font-size: rem(18px);
                }

                &:hover {
                    background-color: color(primary);
                    border-color: color(primary);
                    color: color(black);
                }
            }
        }
    }

    .hs-richtext {
        font-size: rem(12px) !important;
        font-family: $mundial !important; 
        line-height: 1.2;
        color: color(black);

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    .hs-form-booleancheckbox-display {
        input {
            margin: 0;
        }

        span {
            text-transform: initial;
            font-size: rem(14px) !important;
            font-weight: 300;
        }
    }

    .hs_recaptcha {
        margin-top: 20px;
    }

    .hs-form__virality-link {
        display: none;
    }
}