// Ajout de variables CSS
// Si on essai de déclarer une variable CSS comme ceci : `--ma-var: $maVarSass;`, le linter retourne une erreur.
// En utilisant `@include root-prop('--ma-var', $maVarSass);`, plus de soucis.
@mixin root-prop($prop: null, $value: null) {
    @if ($prop and $value) {
        #{$prop}: $value;
    }
}

// POSITION
@mixin absolute-center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin absolute-center-full() {
    @include absolute-center;

    width: 100%;
    height: 100%;
}

// COLOR SVG
@mixin color-svg($color: null) {
    [fill] {
        transition: fill $regular-transition;
        fill: $color;
    }

    [stroke] {
        transition: stroke $regular-transition;
        stroke: $color;
    }
}
