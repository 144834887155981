.block-liste-benefices {
    @include min(breakpoint(md)) {
        margin-top: 135px
    }

    .inner-content-container {
        .heading {
            text-align: center;
            max-width: 900px;
            margin: 0 auto 30px;

            @include min(breakpoint(md)) {
                margin-bottom: 50px
            }
        }
        
        .wrapper-infos {
            @extend %reset-ul;

            display: flex;
            flex-direction: column;
            gap: 30px;

            @include min(breakpoint(md)) {
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: center;
                gap: 60px;
            }

            .info {
                @include min(breakpoint(md)) {
                    max-width: 380px;
                    width: calc((100% / 3) - 40px);
                    position: relative;

                    &:not(:nth-child(3n)) {
                        &::after {
                            content: '';
                            display: flex;
                            width: 2px;
                            height: 100%;
                            background-image: url($path-svg + 'decoration-liste-benefices.svg');
                            background-repeat: repeat-y;
                            position: absolute;
                            right: -30px;
                            top: 0;
                        }
                    }
                }

                .title {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: rem(18px);
                    font-weight: 400;
                    line-height: 1.2;

                    @include min(breakpoint(md)) {
                        font-size: rem(20px);
                    }

                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    .number {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: color(primary);
                        border-radius: 100px;
                        width: 40px;
                        height: 40px;
                    }
                }

                .text {
                    line-height: 1.6;

                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}
