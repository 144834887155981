.block-video {
    &:not(:last-child) {
        margin-bottom: 32px;

        @include min(breakpoint(md)) {
            margin-bottom: 45px;
        }
    }

    .inner-content-container {
        .title {
            margin-bottom: 32px;

            @include min(breakpoint(md)) {
                margin-bottom: 45px;
            }
        }

        .wrapper-video {
            width: 100%;
            height: auto;
            position: relative;
            border-radius: 15px;
            display: flex;
            overflow: hidden;

            .video-thumbnail {
                @include absolute-center-full;
                z-index: 1;

                &.hidden {
                    display: none;
                }

                .icon-play {
                    @include absolute-center;
                    width: 70px;
                    height: 70px;
                    transition: opacity $regular-transition;
                    background: none;
                    border: 0;
                    cursor: pointer;
                    z-index: 2;

                    @include min(breakpoint(md)) {
                        width: 100px;
                        height: 100px;
                    }

                    &:hover {
                        opacity: .5;
                    }

                    svg {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            video,
            .video {
                width: 100%;

                .youtube {
                    display: flex;
                }

                .player {
                    width: 100%;
                    height: auto;
                    aspect-ratio: 640 / 360;
                }
            }
        }

        .legend {
            font-size: rem(14px);
            font-weight: 300;
            line-height: 1.7;
            margin-top: 20px;

            @include min(breakpoint(md)) {
                font-size: rem(16px);
                font-weight: 400;
                line-height: 1.75;
            }
        }
    }
}
