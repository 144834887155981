.block-push-ressource {
    .push {
        background-color: color(primary);
        border-radius: 20px;
        position: relative;

        &.push-style-image {
            .inner-content-container {
                padding: 35px 25px 30px;

                @include min(breakpoint(md)) {
                    padding: 45px 60px;
                }

                .wrapper-text {
                    text-align: center;

                    @include min(breakpoint(md)) {
                        text-align: left;
                        max-width: 735px;
                        width: calc(100% - 275px);
                        padding-right: 0;
                    }
                }

                .wrapper-buttons {
                    align-items: center;
                }
            }
        }

        .inner-content-container {
            padding: 70px 25px 60px;
            z-index: 1;
            position: relative;
            overflow: hidden;

            @include min(breakpoint(md)) {
                display: flex;
                justify-content: space-between;
                padding: 45px 60px;
            }

            :not(.pattern) {
                position: relative;
                z-index: 2;
            }

            .wrapper-text {
                margin-bottom: 30px;

                @include min(breakpoint(md)) {
                    margin-bottom: 0px;
                    padding-bottom: 95px;
                    padding-right: 55px;
                    width: 50%;
                }
            }

            .wrapper-list {
                @extend %reset-ul;

                display: flex;
                flex-direction: column;
                gap: 13px;

                @include min(breakpoint(md)) {
                    padding-left: 75px;
                    border-left: 1px solid color(black);
                    width: 50%;
                }

                .list-item {
                    display: flex;
                    gap: 10px;

                    .list-item-icon {
                        display: flex;
                        flex-shrink: 0;
                        width: 24px;
                        height: 24px;

                        @include min(breakpoint(md)) {
                            width: 32px;
                            height: 32px;
                        }
                    }

                    .list-item-text {
                        font-size: rem(16px);

                        @include min(breakpoint(md)) {
                            font-size: rem(20px);
                        }
                    }
                }
            }

            .wrapper-buttons {
                margin-top: 45px;

                @include min(breakpoint(md)) {
                    margin: 0;
                    position: absolute;
                    bottom: 45px;
                    left: 60px;
                }
            }
        }

        .wrapper-push-image {
            width: 55%;
            max-width: 237px;
            margin: 0 auto;
            box-shadow: 0px 2.573px 19.296px rgba(38, 58, 67, 0.08);

            @include min(breakpoint(md)) {
                position: absolute;
                right: 60px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 3;
            }
        }

        .pattern {
            position: absolute;
            z-index: 0;

            svg {
                path {
                    fill: rgba(255, 255, 255, 0.40);
                }
            }
        
            &.first {
                display: none;

                @include min(breakpoint(lg)) {
                    display: flex;
                    width: 998px;
                    top: -636px;
                    left: -530px;
                }
            }

            &.last {
                width: 998px;
                left: -650px;
                top: -624px;

                @include min(breakpoint(lg)) {
                    right: -553px;
                    top: -636px;
                    left: unset;
                }
            }
        }
    }
}
