.block-tabs {
    --block-tabs-tabs-title-bg: #{color(white10)};
    --block-tabs-text-color: #{color(white)};
    --block-tabs-select-color: #{color(white)};

    overflow: hidden;

    &.light {
        --block-tabs-tabs-title-bg: #{color(white)};
        --block-tabs-select-color: #{color(black)};

        .wrapper-tabs {
            .tabs-title {
                box-shadow: 0px 4px 20px 0px #EEEAE4;
            }
        }
    }

    .heading {
        max-width: 1080px;
        margin: 0 auto 40px;
        text-align: center;
    }

    .wrapper-tabs {
        .tabs-title {
            width: fit-content;
            height: 52px;
            margin: 0 auto 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            border-radius: 100px;
            background-color: var(--block-tabs-tabs-title-bg);
            position: relative;
            

            .tab-title {
                padding: 5px 20px;
                font-size: rem(16px);
                font-weight: 300;
                transition: $transition;
                cursor: pointer;
                position: relative;
                z-index: 1;

                &:hover {
                    &:not(.active) {
                        color: color(primary);
                    }
                }

                &.active {
                    color: color(black);
                }
            }

            .tab-title-active-indicator {
                width: 0;
                height: 42px;
                background-color: color(primary);
                position: absolute;
                top: 5px;
                left: 5px;
                border-radius: 100px;
                transition: width $regular-transition, transform $regular-transition;
            }
        }

        .tabs-title-mobile {
            width: 100%;
            border-radius: 100px;
            height: 40px;
            border: 1px solid var(--block-tabs-select-color);
            margin-bottom: 40px;
            display: flex;
            align-items: center;
            padding: 0 20px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%) rotate(45deg);
                width: 6px;
                height: 6px;
                border-bottom: 1px solid var(--block-tabs-select-color);
                border-right: 1px solid var(--block-tabs-select-color);
                display: block;
            }

            select {
                color: var(--block-tabs-select-color);
                width: 100%;
                appearance: none;
                background: transparent;
                border: 0;
                padding-right: 30px;
                cursor: pointer;
            }
        }

        .tabs-content {
            display: flex;

            @include min(breakpoint(md)) {
                display: block;
            }

            .tab-content {
                display: none;

                @include min(breakpoint(md)) {                    
                    justify-content: space-between;
                    align-items: center;
                    gap: 30px;
                    width: auto;
                }

                &.active {
                    display: block;

                    @include min(breakpoint(md)) {
                        display: flex;
                    }

                    .image-side {
                        transform: translateX(0);
                    }
                }

                .text-side {
                    @include min(breakpoint(md)) {
                        width: 50%;
                        max-width: 600px;
                        margin: 0;
                    }

                    .wrapper-buttons {
                        margin-top: 30px;

                        @include min(breakpoint(md)) {
                            margin-top: 50px;
                        }
                    }
                }

                .image-side {
                    display: none;
                    border-radius: 20px;

                    @include min(breakpoint(md)) {
                        display: flex;
                        width: 50%;
                        max-width: 540px;
                        aspect-ratio: 540/445;
                    }
                }
            }
        }
    }
}
