.block-texte-simple {
    .container {
        .inner-content-container {
            max-width: 900px;
            margin: 0 auto;

            .breadcrumb {
                justify-content: center;
            }

            .title {
                text-align: center;
                margin-bottom: 40px;
            }
        }
    }
}