.wysiwyg-content {
    --color-wysiwyg-list: #{color(primary)};
    --color-wysiwyg-list-content-ol: #{color(black)};
    
    &.wysiwyg-content-black,
    .single-post &,
    .single-guide & {
        --color-wysiwyg-list: #{color(black)};
        --color-wysiwyg-list-content-ol: #{color(white)};
    }

    h1 {
        margin-bottom: 80px;

        @include min(breakpoint(md)) {
            margin-bottom: 55px;
        }
    }

    h2 {
        &:not(:first-child) {
            margin-top: 50px;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    h3 {
        &:not(:first-child) {
            margin-top: 20px;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    h4, h5, h6 {
        &:not(:first-child) {
            margin-top: 20px;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }


    p, ul, ol {
        font-weight: 300;
        margin: 0;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        img {
            margin-top: 15px;
            margin-bottom: 25px;
        }

        &:last-child {
            img {
                margin-bottom: 0;
            }
        }
    }

    hr {
        opacity: 0.5;
        border-color: color(grey);

        &:not(:first-child) {
            margin-top: 35px;
        }

        &:not(:last-child) {
            margin-bottom: 35px;
        }
    }

    a {
        color: color(black);

        &:hover {
            color: color(grey-hover);
            text-decoration: none;
        }
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 15px;
        margin: 32px 0;

        @include min(breakpoint(md)) {
            margin: 45px 0;
        }
    }

    figcaption {
        width: 100%;
        height: auto;

        margin: 32px 0;

        @include min(breakpoint(md)) {
            margin: 45px 0;
        }
    }

    &:not(.custom-list) {
        ul, ol {
            padding-left: 0;

            li {
                &:not(:last-child) {
                    margin-bottom: 4px;
                }
            }
        }

        ul {
            list-style: none;

            li {
                &::before {
                    content: '';
                    background-color: var(--color-wysiwyg-list);
                    display: inline-block;
                    border-radius: 100px;
                    width: 8px;
                    height: 8px;
                    margin-left: 0;
                    margin-right: 12px;
                }

                ul {
                    margin-top: 5px;
                    padding-left: 20px;

                    li {
                        font-size: rem(14px);
                        &::before {
                            content: '';
                            background-color: transparent;
                            border: 1px solid color(black);
                            width: 6px;
                            height: 6px;
                        }
                    }
                }
            }
        }

        ol {
            list-style-type: none;
            counter-reset: li;
            
            li {
                &:not(:last-child) {
                    margin-bottom: 15px;

                    @include min(breakpoint(md)) {
                        margin-bottom: 20px;
                    }
                }

                &::before {
                    counter-increment: li;
                    content: counter(li, decimal-leading-zero);
                    background-color: var(--color-wysiwyg-list);
                    color: var(--color-wysiwyg-list-content-ol);
                    border: 1px solid var(--color-wysiwyg-list);
                    margin-right: 10px;
                    margin-left: 0;
                    width: 40px;
                    height: 40px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 400;
                    font-size: rem(16px);
                    line-height: 0.9;
                    border-radius: 100px;

                    @include min(breakpoint(md)) {
                        font-size: rem(20px);
                    }

                }

                ol {
                    margin-top: 5px;

                    li {
                        font-size: rem(14px);
                        &::before {
                            background-color: transparent;
                            color: color(primary);
                            border: 1px solid color(primary);
                        }
                    }
                }
            }
        }
    }
}
