.header-demande-de-devis {
    position: relative;
    overflow: hidden;

    &.block-spacing {
        padding: 35px 0 50px;
    
        @include min(breakpoint(md)) {
            padding: 85px 0 75px;
        }

    }

    .inner-content-container {
        position: relative;
    }

    .breadcrumb {
      justify-content: center;
    } 

    .pattern {
        position: absolute;

        &.first {
            width: 831px;
            top: 350px;
            left: -610px;
        }

        &.last {
            width: 929px;
            top: 0px;
            right: -690px;
        }
      
        svg {
            path {
                fill: color(primary);
            }
        }
    }

    .wrapper-stepper-contact {
        position: relative;
        border-radius: 20px;
        background-color: color(white);
        color: color(black);
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
        width: 100%;
        max-width: 1020px;
        margin: 0 auto;
        padding: 30px 20px;
        position: relative;
        z-index: 2;
        max-width: 1020px;

        @include min(breakpoint(md)) {
            border-radius: 30px;
            box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.10);
            padding: 70px 60px 45px;
        }


        .stepper-nav {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 27px;
            width: fit-content;
            margin: 0 auto 30px;
            position: relative;

            @include min(breakpoint(md)) {
                gap: 115px;
                margin-bottom: 80px;
            }

            &::after {
                content: '';
                height: 1px;
                width: calc(100% - 95px);
                position: absolute;
                bottom: 6px;            
                left: 50%;
                transform: translateX(-50%);
                background-color: color(black);
            }

            .stepper-nav-button {
                @extend %reset-button;
                cursor: pointer;
                font-size: rem(14px);
                font-weight: 300;
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 10px;
                transition: color $regular-transition;
                position: relative;
                z-index: 1;

                &::after {
                    content: '';
                    display: flex;
                    width: 12px;
                    height: 12px;
                    border: 1px solid color(black);
                    background-color: color(white);
                    border-radius: 100px;
                    transition: background-color $regular-transition;
                }

                &.active {
                    &::after {
                        background-color: color(black);
                    }
                }

                &:hover {
                    color: color(black50);

                    &::after {
                        background-color: color(primary);
                    }
                }
            }
        }

        .step {
            display: none;

            &.active {
                display: block;
            }

            .step-heading {
                text-align: center;
                margin-bottom: 30px;

                @include min(breakpoint(md)) {
                    margin-bottom: 60px;
                }

                .step-title {
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }
            }

            .step-actions {
                display: flex;
                gap: 30px;
                align-items: center;
                justify-content: space-between;
                margin-top: 25px;

                @include min(breakpoint(md)) {
                    margin-top: 50px;
                    flex-direction: row;
                }
            }

            .step-form {
                &.step-form-1 {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    @include min(breakpoint(md)) {
                        gap: 30px;
                        flex-direction: row;
                    }

                    .field {
                        @include min(breakpoint(md)) {
                            width: calc(100% / 3);
                        }
                    }
                }

                &.step-form-2 {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    @include min(breakpoint(md)) {
                        gap: 30px;
                        flex-direction: row;
                    }

                    .field {
                        @include min(breakpoint(md)) {
                            width: calc(100% / 2);

                            &:first-child {
                                width: 72%;
                            }
                        }
                    }

                    .or-separation {
                        text-align: center;
                        position: relative;
                        width: 220px;
                        display: flex;
                        align-items: center;
                        flex-shrink: 0;
                        margin: 0 auto;
                        margin-top: 25px;

                        @include min(breakpoint(md)) {
                            width: 220px;
                            font-size: rem(18px);

                            &::after {
                                @include absolute-center;
                                content: '';
                                width: 100%;
                                height: 1px;
                                background-color: color(light-grey);
                            }
                        }

                        .or {
                            display: inline-block;
                            width: 60px;
                            background-color: color(white);
                            margin: 0 auto;
                            position: relative;
                            z-index: 1;
                        }
                    }
                }

                &.step-form-3 {
                    & + .step-actions {
                        width: 45%;
                        position: absolute;
                        bottom: 63px;
    
                        @include min(breakpoint(md)) {
                            bottom: 80px;
                        }
                    }
                }
            }
        }
    }
}
