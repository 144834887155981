/// Path to assets
$path-jpg: '../img/jpg/';
$path-png: '../img/png/';
$path-svg: '../img/svg/';

/// Breakpoints
/// @see {function} breakpoint
$breakpoints: (
    xs: 360,
    sm: 576,
    md: 768,
    nav: 960,
    lg: 1040,
    l: 1100,
    xl: 1200,
    xxl: 1440,
);

/// Couleurs
/// @see {function} color
$colors: (
    white: #fff,
    white10: rgba(255, 255, 255, 0.10),
    white20: rgba(255, 255, 255, 0.20),
    white50: rgba(255, 255, 255, 0.50),
    black: #000,
    black20: rgba(0, 0, 0, 0.20),
    black50: rgba(0, 0, 0, 0.50),
    grey: #C6DFEB,
    grey-hover: #919191,
    light-grey: #D2DFE0,
    dark-grey:#1D1D1B,
    secondary-dark-grey:#8E9798,
    primary: #FFEB00,
    secondary: #FAF7F2,
    dark-beige: #DFD9CE,
    tag-beige: #FAF1E2,
    red: #EF1F1F,
);

/// Font
$base-font-size: 16px;

$mundial: 'mundial', sans-serif;

/// Container
$container-width: 1260px;
$container-width-large: 1380px;
$container-width-medium: 1020px;
$container-width-small: 840px;
$container-x-padding: 20px;
$container-large-x-padding: 20px;

//Spacing
$spacing-top-bottom-desktop: 100px;
$spacing-top-bottom-mobile: 50px;

/// Height
$height-nav-primary: 106px;
$height-nav-primary-sticky: 80px;
$height-nav-primary-mobile: 52px;
$height-summary-mobile: 72px;

/// Radius
$button-radius: 150px;
$round-radius: 50%;

/// Transition
$regular-transition-duration: 300ms;
$regular-transition: $regular-transition-duration ease-out;
$transition: all $regular-transition-duration ease-out;

// Background
$degrade-light-blue: linear-gradient(161.28deg, #FFFFFF 0%, #E5F6FC 96.94%);
$degrade-light-light-blue: linear-gradient(90deg, #00A6FF 8.33%, #44DDFF 100%);