.post-card {
    width: 100%;
    height: 100%;
    margin: 0 auto;

    .card-link {
        text-decoration: none;
        background: transparent;
        border: 0;
        color: inherit;
        text-align: left;
        width: 100%;
        display: block;

        &:not(.inactive) {
            cursor: pointer;

            &:hover {
                .card-thumbnail {
                    img {
                        transform: scale(1.1);
                    }
                }

                .card-title {
                    color: color(grey-hover);
                }
            }
        }
    }

    .card-thumbnail {
        position: relative;
        margin-bottom: 25px;
        aspect-ratio: 300/190 ;
        border-radius: 20px;
    }

    .card-post-info {
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .card-categories-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
        flex-grow: 1;
        width: calc(100% - 110px);
    }

    .card-tag {
        background-color: color(tag-beige);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 210px;

        @include min(breakpoint(sm)) {
            max-width: unset;
        }

        .tag-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .card-date {
        color: color(black);
        font-size: rem(12px);
        font-weight: 300;
        line-height: 170%;
        opacity: .5;
        flex-shrink: 1;
    }

    .card-title {
        font-weight: 400;
        font-size: rem(18px);
        line-height: 1.2;
        transition: color $regular-transition;
        color: color(black);

        @include min(breakpoint(md)) {
            font-size: rem(20px);
        }
    }

    .card-excerpt {
        font-weight: 200;
        font-size: rem(14px);
        margin-top: 20px;
        color: color(black);
        line-height: 1.5;

        @include min(breakpoint(md)) {
            font-size: rem(16px);
            flex-grow: 1;
        }
    }

    .wrapper-buttons {
        margin-top: 20px;
    }
 
    // CARD HIGHLIGHT
    &.card-sticky {
        background-color: white;
        padding: 20px;
        border-radius: 30px;
        max-width: 100%;
        position: relative;
        z-index: 2;
        box-shadow: 0px 4px 70px 0px rgba(0, 0, 0, 0.08);

        .card-link {
            display: flex;
            flex-direction: column;
            gap: 30px;

            @include min(breakpoint(md)) {
                flex-direction: row;
                gap: 70px;
            }
        }

        .card-thumbnail {
            margin: 0;

            @include min(breakpoint(md)) {
                aspect-ratio: 600/390;
                width: 50%;
                max-width: 600px;
            }
        }

        .card-wrapper-text {
            @include min(breakpoint(md)) {
                width: 50%;
                max-width: 510px;
                padding: 20px 0;
                display: flex;
                flex-direction: column;
            }

            .card-title {
                font-size: rem(32px);
                font-weight: 400;
                line-height: 1.2;
                letter-spacing: -0.96px;
            }
        }
    }
}