.block-manifest-chiffres-cles {
    color: color(white);
    overflow: hidden;
    position: relative;
    padding-bottom: 60px !important;

    @include max(breakpoint(md)) {
        padding-bottom: 0 !important;
    }

    .inner-content-container {
        .container {
            max-width: 800px;
            text-align: center;
            position: relative;
            z-index: 1;

            .title {
                margin-bottom: 20px;

                @include min(breakpoint(md)) {
                    margin-bottom: 25px;
                }
            }

            .text {
                line-height: 2;

                @include min(breakpoint(md)) {
                    line-height: 1.8;
                }
            }

            .wrapper-buttons {
                align-items: center;
                margin-top: 20px;

                @include min(breakpoint(md)) {
                    justify-content: center;
                    margin-top: 35px;
                }
            }
        }

        .scroll-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            margin: 0 auto;
            margin-top: 50px;
            z-index: 1;

            @include min(breakpoint(md)) {
                margin-top: 170px;
            }
    
            &:hover {
                .chiffres-cles {
                    animation-play-state: paused;
                }
            }
        }
    
        .chiffres-cles {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            
            &.chiffres-cles-slide {
                animation: var(--animation-slide-timing, 10s) slide infinite linear;
            }
    
            .chiffre-cle {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                margin: 0 20px;
    
                @include min(breakpoint(md)) {
                    margin: 0 50px;
                }
            }
        }

        .wrapper-image {
            margin-top: 10px;
            position: relative;

            @include min(breakpoint(md)) {
                @include absolute-center-full;

                z-index: 0;
                margin-top: 0;
            }
        }
    }
}