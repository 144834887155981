.header-contact,
.header-remerciement {
    position: relative;
    overflow: hidden;

    &.block-spacing {
        padding: 35px 0 40px;
    
        @include min(breakpoint(md)) {
            padding: 85px 0 40px;
        }

    }

    &::after {
        content: '';
        height: 41px;
        width: calc(var(--vw, 100vw) + 2px);
        background-color: color(white);
        position: absolute;
        left: 50%;
        bottom: -1px;
        transform: translateX(-50%);
        z-index: 1;
    }

    .inner-content-container {
        position: relative;
    }

    .breadcrumb {
      justify-content: center;
    } 

    .pattern {
        position: absolute;

        &.first {
            width: 758px;
            top: 350px;
            left: -460px;
        }

        &.last {
            width: 929px;
            top: 0px;
            right: -550px;
        }
      
        svg {
            path {
                fill: color(primary);
            }
        }
    }

    &.has-dark-grey-bg-color {
        .pattern {
            svg {
                path {
                    fill: unset;
                    stroke: color(primary);
                }
            }
        }
    }

    .wrapper-content {
        max-width: 900px;
        margin: 0 auto;
        text-align: center;
        position: relative;
        z-index: 2;

        &:not(:last-child) {
            margin-bottom: 30px;

            @include min(breakpoint(md)) {
                margin-bottom: 40px;
            }
        }

        .title {
            &:not(:last-child) {
                margin-bottom: 20px;

                @include min(breakpoint(md)) {
                    margin-bottom: 30px;
                }
            }
        }

        .text {
            font-weight: 300;

            @include min(breakpoint(md)) {
                font-size: rem(20px);
            }
        }
    }

    .wrapper-formulaire {
        position: relative;

        .formulaire-inner {
            border-radius: 20px;
            background-color: color(white);
            color: color(black);
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
            width: 100%;
            max-width: 1020px;
            margin: 0 auto;
            padding: 30px 20px;
            position: relative;
            z-index: 2;
    
            @include min(breakpoint(md)) {
                border-radius: 30px;
                box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.10);
                padding: 60px;
            }
        }

        &::after {
            content: '';
            height: 45%;
            width: calc(var(--vw, 100vw) + 2px);
            background-color: color(white);
            position: absolute;
            left: 50%;
            bottom: -1px;
            transform: translateX(-50%);
            z-index: 1;
        }
    }
}

.header-remerciement {

    &.block-spacing {
        padding: 35px 0 40px;
    
        @include min(breakpoint(md)) {
            padding: 85px 0 80px;
        }

    }

    &::after {
        display: none;
    }

    .wrapper-formulaire {
        text-align: center;

        &::after {
            display: none;
        }

        .title {
            &:not(:last-child) {
                margin-bottom: 20px;

                @include min(breakpoint(md)) {
                    margin-bottom: 30px;
                }
            }
        }

        .text {
            font-weight: 300;
            max-width: 480px;
            margin: 0 auto;

            @include min(breakpoint(md)) {
                font-size: rem(20px);
            }
        }

        .wrapper-buttons {
            margin-top: 40px;
            justify-content: center;
            align-items: center;
        }
    }
}
