.header-archive {
    overflow: hidden;
    position: relative;

    .container {
        position: relative
    }

    .breadcrumb {
        justify-content: center;
    }

    .heading {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 16px;
        max-width: 900px;
        margin: 0 auto;

        &:not(:last-child) {
            margin-bottom: 30px;

            @include min(breakpoint(md)) {
                margin-bottom: 70px;
            }
        }

        .text {
            margin-top: 20px;
            line-height: 1.6;

            @include min(breakpoint(md)) {
                margin-top: 30px;
                font-size: rem(20px);
            }
        }
    }

    .pattern {
        width: 982px;
        position: absolute;
        top: 95px;
        right: -695px;

        svg {
            path {
                fill: color(primary);
            }
        }
    }

    &.is-home {
        &::after {
            content: '';
            display: block;
            background-color: color(white);
            width: 100%;
            height: 180px;
            position: absolute;
            bottom: -1px;
            left: 0;
        }
    }

    &:not(.is-home) {
        margin-bottom: 40px;

        @include min(breakpoint(md)) {
            margin-bottom: 70px;
        }
    }

    &.has-dark-grey-bg-color {
        .pattern {
            svg {
                path {
                    fill: unset;
                    stroke: color(primary);
                }
            }
        }
    }
}

.archive-list {
    .container {
        @include min(breakpoint(xl)) {
            display: flex;
            gap: 60px
        }
    }

    .aside-content {
        @include min(breakpoint(xl)) {
            width: 240px;
            flex-shrink: 0;
        }

        .aside-content-inner {
            display: flex;
            flex-direction: column;
            gap: 30px;

            @include min(breakpoint(xl)) {
                position: sticky;
                top: 140px;
            }

            .push-aside {
                display: none;

                @include min(breakpoint(xl)) {
                    display: flex;
                }
            }
        }
    }

    .push-aside {
        position: relative;
        overflow: hidden;
        background-color: color(black);
        background-image: url($path-svg + 'decoration-push-aside.svg');
        background-repeat: no-repeat;
        background-position: top right;
        border-radius: 20px;
        min-height: 200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        text-decoration: none;
        padding: 20px;

        @include min(breakpoint(md)) {
            min-height: 225px;
        }

        .icon {
            display: flex;
            background-color: color(white);
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            width: 50px;
            height: 50px;
            position: relative;
            z-index: 1;

            img {
                width: 24px;
                height: auto;
            }
        }

        .title {
            color: color(white);
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-size: rem(18px);
            line-height: 1.2;
            font-weight: 400;
            transition: color $regular-transition;
            position: relative;
            z-index: 1;

            @include min(breakpoint(md)) {
                font-size: rem(20px);
            }
        }

        .wrapper-image {
            @include absolute-center-full;
        }

        &:hover {
            .title {
                color: color(primary);
            }

            .wrapper-image {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    .main-content {
        .heading-category {
            margin-bottom: 40px;

            @include min(breakpoint(md)) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 30px;
            }

            .heading-category-content {
                max-width: 670px;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            .button-wrapper {
                flex-shrink: 0;
                margin-top: 20px;

                @include min(breakpoint(md)) {
                    margin-top: 0;
                }
            }
        }

        .push-aside {
            margin: 50px 0;
        }
    }

    &.archive-list-posts {
        margin-top: 0px;

        .grid-posts {
            &:not(.swiper-wrapper) {
                display: grid;
                grid-gap: 40px;
            
                @include min(breakpoint(sm)) {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    grid-gap: 40px 30px;
                }
            
                @include min(breakpoint(lg)) {
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                }
            }
        }
    }

    .grid-posts {
        &:not(:last-child) {
            margin-bottom: 50px;

            @include min(breakpoint(md)) {
                margin-bottom: 90px;
            }
        }
    }

    .terms-list-wrapper {
        margin-bottom: 40px;

        @include min(breakpoint(xl)) {
            margin-bottom: 0;
        }

        .terms-list-title {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: rem(18px);
            font-weight: 400;
            line-height: 1;
            letter-spacing: -0.4px;
            margin-bottom: 10px;

            @include min(breakpoint(md)) {
                font-size: rem(20px);
                margin-bottom: 20px;
            }

            svg {
                flex-shrink: 0;
            }
        }

        .terms-list {
            @extend %reset-ul;

            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            @include min(breakpoint(xl)) {
                flex-direction: column;
                gap: 15px;
            }

            .terms-list-item {
                a {
                    text-decoration: none;

                    &:hover,
                    &.active {
                        color: color(white);
                        background-color: color(black);
                    }
                }
            }
        }
    }
}
