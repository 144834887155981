/// Créé une media query avec un min-width correspondant à la valeur renseignée
///
/// @param {String} $value
///
/// @output @media 'min-width'
@mixin min($value) {
    @media (min-width: calc(#{$value} + 1px)) {
        @content;
    }
}

/// Créé une media query avec un max-width correspondant à la valeur renseigné
///
/// @param {String} $value
///
/// @output @media 'max-width'
@mixin max($value) {
    @media (max-width: $value) {
        @content;
    }
}

/// Créé une media query avec un min-width et max-width correspondant aux valeurs renseignées
///
/// @param {String} $value-min
/// @param {String} $value-max
///
/// @output @media 'min-width' 'max-width'
@mixin min-max($value-min, $value-max) {
    @media (min-width: #{$value-min}) and (max-width: $value-max) {
        @content;
    }
}
