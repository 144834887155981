.page-404 {
    overflow: hidden;
    position: relative;
    background-color: color(secondary);
    min-height: calc(100vh);
    display: flex;

    .container {
        display: flex;
        flex-direction: column;
    }

    .breadcrumb-container {
        .breadcrumb {
            justify-content: center;
        }
    }

    .page-404-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        height: 100%;
        position: relative;
        z-index: 1;

        .title {
            font-size: rem(80px);
            font-weight: 400;
            line-height: 1;
            color: color(primary);
            margin-bottom: 30px;

            @include min(breakpoint(md)) {
                font-size: rem(120px);
            }
        }

        .text {
            font-size: rem(25px);
            font-weight: 400;
            line-height: 1.6;
            text-align: center;
            margin-bottom: 50px;

            @include min(breakpoint(md)) {
                font-size: rem(32px);
            }
        }
    }

    .pattern {
        position: absolute;

        &.first {
            width: 950px;
            bottom: -600px;
            left: -550px;
            transform: rotate(95deg);

            svg {
                path {
                    stroke: color(black);
                }
            }
        }

        &.last {
            width: 950px;
            bottom: -520px;
            right: -600px;
            transform: rotate(95deg);

            svg {
                path {
                    fill: color(primary);
                }
            }
        }
    }
}
