.block-push-double {
    position: relative;
    overflow: hidden;
    background-color: color(primary);

    .inner-content-container {
        @include min(breakpoint(md)) {
            display: flex;
        }
    }

    .push {
        position: relative;
        overflow: hidden;
        padding: 70px 20px;

        @include min(breakpoint(md)) {
            width: 50%;
            padding: 85px 20px 80px;
        }

        .wrapper-content {
            @include min(breakpoint(md)) {
                max-width: 630px;
            }
        }

        .title,
        .wrapper-buttons {
            position: relative;
            z-index: 2;
        }

        .title {
            font-size: rem(32px);
            text-align: center;

            &:not(:last-child) {
                margin-bottom: 40px;

                @include min(breakpoint(md)) {
                    margin-bottom: 50px;
                }
            }
        }

        .wrapper-buttons {
            justify-content: center;
            align-items: initial;
        }

        &.b-to-c {
            background-color: color(primary);

            .wrapper-content {
                @include min(breakpoint(md)) {
                    margin-left: auto;
                }
            }
    
            .pattern {
                position: absolute;
    
                svg {
                    path {
                        fill: rgba(255, 255, 255, 0.40);
                    }
                }
            
                &.first {
                    display: none;
    
                    @include min(breakpoint(md)) {
                        display: flex;
                        width: 1468px;
                        top: -1193px;
                        left: -1015px;
                    }
                }
    
                &.last {
                    width: 1251px;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -995px;
    
                    @include min(breakpoint(md)) {
                        width: 1645px;
                        transform: translateY(-50%);
                        right: -1496px;
                        top: 50%;
                        left: unset;
                    }
                }
            }
        }
    
        &.b-to-b  {
            background-color: color(dark-grey);
            color: color(white);
    
            .pattern {
                position: absolute;
                display: flex;
    
                svg {
                    path {
                        stroke: color(primary);
                    }
                }
            
                &.first {
                    width: 1300px;
                    top: -1243px;
                    left: -721px;
    
                    @include min(breakpoint(md)) {
                        width: 1468px;
                        top: -1210px;
                        left: -1015px;
                    }
                }
    
                &.last {
                    width: 1300px;
                    bottom: -1190px;
                    right: -734px;
    
                    @include min(breakpoint(md)) {
                        width: 1645px;
                        transform: translateY(-50%);
                        right: -1496px;
                        top: 50%;
                        bottom: unset;
                    }
                }
            }
        }
    }
}
