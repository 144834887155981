.block-accordeon {
    position: relative;

    .inner-content-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 1020px;
        margin: 0 auto;

        @include min(breakpoint(md)) {
            gap: 40px;
        }
    }

    .heading {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include min(breakpoint(md)) {
            gap: 20px;
        }

        .title,
        .text {
            text-align: center;
        }
    }

    .wrapper-buttons {
        width: fit-content;
        margin: 0 auto;
    }

    .accordeons-list {
        @extend %reset-ul;

        .accordeons-item {
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .dropdown {
                background-color: color(white);
                border: 1px solid rgba(0, 0, 0, 0.15);
                padding: 30px 20px;
                border-radius: 1000px;

                @include min(breakpoint(md)) {
                    padding: 30px;
                }

                &[open] {
                    border-radius: 20px;

                    .dropdown-title {
                        .dropdown-icon {
                            &::after {
                                transform: translate(-50%, -50%) rotate(0deg);
                            }
                        }
                    }
                }

                .dropdown-title {
                    position: relative;
                    display: flex;
                    gap: 25px;
                    align-items: flex-start;
                    justify-content: space-between;
                    cursor: pointer;
                    padding-right: 42px;

                    @include min(breakpoint(md)) {
                        padding-right: 60px;
                    }

                    &::-webkit-details-marker {
                        display: none;
                    }

                    .dropdown-icon {
                        position: absolute;
                        top: -4px;
                        right: 0;
                        width: 32px;
                        height: 32px;
                        flex-shrink: 0;
                        border-radius: 100px;
                        background-color: color(black);

                        @include min(breakpoint(md)) {
                            top: -10px;
                            width: 50px;
                            height: 50px;
                        }

                        &::after,
                        &::before {
                            @include absolute-center;
                            transition: transform $regular-transition;
                            content: '';
                            width: 40%;
                            height: 1px;
                            background-color: color(white);
                            transform-origin: center;
                        }

                        &::after {
                            transform: translate(-50%, -50%) rotate(90deg);
                        }
                    }
                }

                .dropdown-content {
                    padding-top: 17px;
                    font-size: rem(14px);
                    font-weight: 300;
                    
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    &.has-bg-color {
        .accordeons-list {
            .accordeons-item {
                border-color: color(white);
                .dropdown {
                    .dropdown-title {
                        &::after {
                            border-color: color(white);
                        }
                    }
                }
            }
        }
    }
}