.block-type-content {
    margin: 40px 0;

    @include min(breakpoint(md)) {
        margin: 80px 0;
    }

    .container {
        .inner-content-container {
            .element-has-margin-bottom {
                margin-bottom: 40px !important;

                @include min(breakpoint(md)) {
                    margin-bottom: 50px !important;
                }
            }

            .wysiwyg-content {
                em {
                    font-weight: 400;
                    font-style: italic;
                    font-size: inherit;
                }

                ul,
                ol {
                    li {
                        margin-bottom: 8px;
                    }
                }
            }

            .code-block-wrapper {
                padding: 24px;
                margin: 20px 0;
                background: #e7ebf34d;
                border: 1px solid color(primary);
                border-radius: 8px;
                font-family: $mundial;
                color: color(primary);
            }

            .image-wrapper {
                border-radius: 4px;
                overflow: hidden;
                display: flex;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .video-wrapper {
                position: relative;
                margin: 15px 0;
                aspect-ratio: 16/9;
                border-radius: 10px;
                overflow: hidden;
                cursor: pointer;

                @media(hover: hover) {
                    &:hover {
                        &::after {
                            transform: scale(1.06);
                        }
                    }
                }

                @include min(breakpoint(md)) {
                    aspect-ratio: unset;
                    height: 390px;
                }

                &::before {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
                    content: '';
                }

                &::after {
                    display: block;
                    position: absolute;
                    top: calc(50% - 17px);
                    left: calc(50% - 17px);
                    width: 34px;
                    height: 34px;
                    z-index: 2;
                    background-image: url($path-svg + 'play.svg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    content: '';
                    transition: $transition;

                    @include min(breakpoint(md)) {
                        top: calc(50% - 40px);
                        left: calc(50% - 40px);
                        width: 80px;
                        height: 80px;
                    }
                }

                img {
                    position: absolute;
                    inset: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &--play {

                    &::after,
                    &::before {
                        display: none;
                    }

                    img {
                        display: none;
                    }
                }
            }

            .push-ressource-wrapper {
                margin: 20px 0;

                .block-push-ressource {
                    .container {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }

            .temoignage-wrapper {
                .quote {
                    margin: 0 0 35px 0;
                    padding-left: 16px;
                    border-left: 3px solid color(primary);
                    font-size: rem(22px);
                    font-weight: 500;
                    line-height: rem(29px);
                    overflow-wrap: break-word;
                }

                .author {
                    display: flex;
                    padding-left: 16px;
                    align-items: center;
                    gap: 16px;

                    .portrait-wrapper {
                        height: 56px;
                        width: 56px;
                        overflow: hidden;
                        border-radius: 8px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .author-info {
                        font-size: rem(14px);
                        line-height: rem(21px);

                        .author-name {
                            font-weight: 500;
                        }

                        .author-position {
                            font-weight: 400;
                            color: color(grey);
                        }
                    }
                }
            }

            .key-element-wrapper {
                border-left: 1px solid #00B0FB;
                border-right: 1px solid rgba(#00B0FB, .2);
                background-image: linear-gradient(90deg, #00B0FB, rgba(#00B0FB, .2)), linear-gradient(90deg, #00B0FB, rgba(#00B0FB, .2));
                background-size: 100% 1px;
                background-position: 0 0, 0 100%;
                background-repeat: no-repeat;
                border-radius: 10px;
                padding: 32px;
                box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);

                @include min(breakpoint(md)) {
                    padding: 32px 38px;
                }

                .tag {
                    font-size: rem(12px);
                    display: inline-flex;
                    color: color(primary);
                    text-transform: uppercase;
                    padding: 6px 10px;
                    margin-bottom: 15px;
                    background: rgba(0, 176, 251, 0.2);
                    border-radius: 100px;
                }

                .title {
                    margin-bottom: 15px;
                }

                .content {
                    font-size: rem(16px);
                    font-weight: 400;
                    line-height: rem(26px);
                }
            }


        }
    }
}