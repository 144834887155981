.block-image-pleine-largeur {
    .inner-content-container {
        aspect-ratio: 375/250;

        @include min(breakpoint(md)) {
            aspect-ratio: unset;
            width: 100%;
            height: 615px;
        }
    }
}
