.header-accueil {
    position: relative;
    background-color: color(secondary);
    overflow: hidden;

    &.block-spacing {
        margin: 0;
        padding: 80px 0 95px;
    }

    .inner-content-container {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 35px;

        @include min(breakpoint(md)) {
           flex-direction: row;
           justify-content: space-between;
        }

        .header-accueil-content {
            position: relative;
            z-index: 1;

            @include min(breakpoint(md)) {
                width: 55%;
                max-width: 660px;
            }

            .switch-context {
                margin-bottom: 25px;
                display: flex;
                align-items: center;
                width: fit-content;
                height: 40px;
                background-color: color(white);
                border-radius: 50px;
                padding: 5px;
                box-shadow: 0px 4px 20px 0px #EAE1D2;

                @include min(breakpoint(md)) {
                    margin-bottom: 15px;
                }

                .switch-link {
                    padding: 0 13px;
                    border-radius: 50px;
                    height: 30px;
                    text-align: center;
                    text-decoration: none;
                    font-size: rem(16px);
                    display: flex;
                    align-items: center;
                    position: relative;
                    z-index: 1;

                    &.switch-link--active-ready {
                        background-color: color(primary);
                    }
                }

                .switch-context-active-indicator {
                    width: 0;
                    height: 30px;
                    background-color: color(primary);
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    border-radius: 100px;

                    &.switch-context-active-indicator-ready {
                        transition: width $regular-transition, transform $regular-transition;
                    }
                }
            }

            .title {
                &:not(:last-child) {
                    margin-bottom: 10px;

                    @include min(breakpoint(md)) {
                        margin-bottom: 20px;
                        max-width: calc(100% - 110px);
                    }
                }
            }
    
            .text {    
                @include min(breakpoint(md)) {
                    font-size: rem(20px);
                    max-width: calc(100% - 110px);
                }
            }

            .formulaire {
                position: relative;
                background-color: color(white);
                box-shadow: 0px 4px 24px 0px #F0E9DD;
                padding: 30px 20px;
                border-radius: 10px;
                margin-top: 20px;

                @include min(breakpoint(md)) {
                    padding: 20px 30px;
                    margin-top: 20px;
                }

                .formulaire-decoration {
                    display: flex;
                    position: absolute;
                    right: -20px;
                    top: -180px;
                }

                .row {
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    &.submit {
                        margin-top: 20px;
                    }
                }
            }
        }

        .header-accueil-image {
            position: relative;
            width: 100%;
            aspect-ratio: 335/240;
    
            @include min(breakpoint(md)) {
                width: 45%;
                max-width: 480px;
                aspect-ratio: unset;
            }

            .wrapper-image {
                border-radius: 20px;
                position: relative;
                z-index: 1;
                aspect-ratio: 335/240;

                @include min(breakpoint(md)) {
                    aspect-ratio: 480/565;
                }

                video {
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }
            }

            .pastille {
                display: none;

                @include min(breakpoint(md)) {
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    display: flex;
                    align-items: center;
                    height: 50px;
                    background-color: color(white);
                    border-radius: 50px;
                    padding: 5px;
                }

                .pastille-text {
                    font-weight: 400;
                    padding: 0 20px;
                }

                .pastille-images {
                    display: flex;

                    .image {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        border-radius: 100px;
                        border: 1px solid color(white);

                        &:not(:first-child) {
                            margin-left: -16px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

            .pattern {
                position: absolute;
                width: 274px;
                top: 50%;
                right: -190px;
                transform: translateY(-50%);

                @include min(breakpoint(md)) {
                   width: 712px;
                   right: -555px;
                }

                svg {                    
                    path {
                        fill: color(primary);
                    }
                }
            }
        }
    }
}
