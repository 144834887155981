.block-manifest {
    .inner-content-container {
        .tag {
            background-color: color(white);
            margin: 0 auto;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        .text {
            max-width: 840px;
            margin: 0 auto;
            text-align: center;

            @include min(breakpoint(md)) {
                line-height: 1.56;
            }
        }
    }
}