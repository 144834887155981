@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes slide-reverse {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}