.breadcrumb {
    @extend %reset-ul;
    display: flex;
    font-size: rem(10px);
    font-weight: 400;
    color: inherit;
    line-height: 1.2;
    margin-bottom: 35px;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;

    @include min(breakpoint(md)) {
        margin-bottom: 45px;
        font-size: rem(12px);
    }

    .breadcrumb-item {
        display: flex;
        align-items: center;
        gap: 10px;
        opacity: 1;

        &.current {
            opacity: 0.5;
        }

        &:not(:last-child) {
            &::after {
                content: '';
                display: inline-flex;
                width: 4px;
                height: 4px;
                border-right: 1px solid currentColor;
                border-bottom: 1px solid currentColor;
                transform: rotate(-45deg);
                transition: $transition;
                flex-shrink: 0;
            }
        }

        a {
            text-decoration: none;

            &:hover {
                opacity: 1;
                text-decoration: underline;
            }
        }
    }
}
