.main-footer {
    .main-footer-content {
        padding: 40px 0;

        @include min(breakpoint(md)) {
            padding: 60px 0 25px;
        }
    }

    .main-footer-top {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding-bottom: 30px;

        @include min(breakpoint(md)) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            gap: 30px;
        }
    }

    .main-footer-bottom {
        padding-top: 30px;
        border-top: 1px solid color(light-grey);
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 15px;

        @include min(breakpoint(md)) {
            padding-top: 25px;
            flex-direction: row;
            justify-content: space-between;
            gap: 30px;
        }
    }

    .main-footer-logos {
        width: 100%;

        @include min(breakpoint(md)) {
            width: auto;
        }

        .main-footer-logo {
            width: 100%;

            @include min(breakpoint(md)) {
                width: 200px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .wrapper_language_switcher {  
            margin-top: 20px;    

            .wpml-ls-legacy-dropdown-click {        
                a {
                    font-size: rem(14px);
                    font-weight: 200;
                    color: color(black) !important;

                    &.wpml-ls-item-toggle {
                        &::before {
                            border-color: color(black);
                        }
                    }
                }
            }
        }
    }
    
    .main-footer-primary-menu {
        width: 100%;

        @include min(breakpoint(md)) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            gap: 100px;
            flex-grow: 1;
            flex-wrap: wrap;
            width: auto;
            max-width: 780px;
        }

        .menu {
            &:not(:last-child) {
                margin-bottom: 30px;

                @include min(breakpoint(md)) {
                    margin: 0;
                }
            }

            .col-menu-title {
                color: color(black);
                font-size: rem(14px);
                font-weight: 600;
                letter-spacing: 1.12px;
                text-transform: uppercase;
                display: flex;
                justify-content: space-between;
                gap: 10px;
                align-items: center;
                margin-bottom: 15px;
            }

            .col-menu-content {
                font-size: rem(16px);
                font-weight: 200;

                &.col-menu-content--adress {
                    line-height: 2.2;
                }

                .col-menu-content-links {
                    @extend %reset-ul;

                    .link {
                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }

                        .main-link {
                            font-size: rem(16px);
                            font-weight: 200;
                            color: inherit;
                            text-decoration: none;            

                            &:hover {
                                color: color(secondary-dark-grey);
                                text-decoration: underline
                            }
                        }
                    }
                }
            }

            .social-networks-wrapper {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                gap: 10px;
                z-index: 1;
                margin-top: 15px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;

                    svg {
                        @include color-svg(black);
                    }

                    &:hover {
                        svg {
                            @include color-svg(color(primary));
                        }
                    }
                }
            }
        }
    }

    .copyright {
        color: color(secondary-dark-grey);
        font-size: rem(14px);
        margin-bottom: 12px;

        @include min(breakpoint(md)) {
            margin-bottom: 0;
        }
    }

    .main-footer-secondary-menu {
        .secondary-menu {
            @extend %reset-ul;
            display: flex;
            gap: 12px;
            flex-direction: row;
            flex-wrap: wrap;

            @include min(breakpoint(md)) {
                gap: 40px;
                align-items: center;
            }

            .secondary-menu-link {
                color: color(secondary-dark-grey);
                text-decoration: none;
                font-size: rem(14px);

                &:hover {
                    color: color(black);
                    text-decoration: underline;
                }
            }
        }
    }
}
