.block-implantation {
    .inner-content-container {
        .heading {
            max-width: 900px;
        }

        .title {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }

        .text {
            @include min(breakpoint(md)) {
                font-size: rem(20px);
            }
        }

        & > .wrapper-image {
            display: none;

            @include min(breakpoint(md)) {
                display: flex;
                margin-top: 70px;
            }
        }

        .wrapper-list {
            @extend %reset-ul;

            display: flex;
            flex-direction: column;
            gap: 13px;
            margin-top: 30px;

            @include min(breakpoint(md)) {
                flex-direction: row;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                gap: 20px 25px;
                margin-top: 50px;
            }

            .list-item {
                display: flex;
                gap: 10px;
                align-items: center;

                &:not(:last-child) {
                    &::after {
                        display: flex;
                        width: 7px;
                        height: 7px;
                        background-color: currentColor;
                        margin-left: 15px;
                        border-radius: 100px;
    
                        @include min(breakpoint(md)) {
                            content: '';
                        }
                    }
                }

                .list-item-icon {
                    display: flex;
                    flex-shrink: 0;
                    width: 24px;
                    height: 24px;
                }

                .list-item-text {
                    font-size: rem(16px);

                    @include min(breakpoint(md)) {
                        font-size: rem(18px);
                    }
                }
            }
        }
    }
}
