@at-root :root {
    @each $color, $value in $colors {
        #{--color-#{$color}}: #{$value};
    }

    // Breakpoints
    @each $breakpoint, $size in $breakpoints {
        #{--breakpoint-#{$breakpoint}}: #{$size};
    }

    --admin-bar-height: 0px;
}

// Colors
@each $color,
$value in $colors {
    .has-#{'' + $color}-color {
        color: color($color) !important;
    }
}

// Backgrounds
@each $color,
$value in $colors {
    .has-#{'' + $color}-bg-color {
        background-color: color($color) !important;
    }

    .has-#{'' + $color}-bg-color + .has-#{'' + $color}-bg-color {
        padding-top: 0 !important;
    }
}

.admin-bar {
    --admin-bar-height: 46px;

    @media (min-width: 783px) {
        --admin-bar-height: 32px;
    }
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent !important;
}


html {
    font-size: $base-font-size;
}

html, body {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: $mundial;
    font-size: rem($base-font-size);
    font-weight: 300;
    line-height: 1.6;
    color: color(black);

    @include max(breakpoint(md)) {
        font-size: rem(14px);
    }

    &.prevent-scroll {
        height: var(--vh);
        overflow: hidden;
    }
}

.container, %container {
    margin: 0 auto;
    padding: 0 $container-x-padding;
    width: 100%;
    max-width: calc($container-width + ($container-x-padding * 2));

    &.is-container-small {
        max-width: calc($container-width-small + ($container-x-padding * 2));
    }

    &.is-container-large {
        max-width: calc($container-width-large + ($container-large-x-padding * 2));
    }
}

.block-spacing {
    scroll-margin-top: calc(#{$height-nav-primary-mobile});
    margin-top: $spacing-top-bottom-mobile;
    margin-bottom: $spacing-top-bottom-mobile;

    @include min(breakpoint(md)) {
        scroll-margin-top: calc(#{$height-nav-primary});
        margin-top: $spacing-top-bottom-desktop;
        margin-bottom: $spacing-top-bottom-desktop;
    }

    &:not(.has-transparent-bg-color) {
        margin: 0;
        padding-top: $spacing-top-bottom-mobile;
        padding-bottom: $spacing-top-bottom-mobile;

        @include min(breakpoint(md)) {
            padding-top: $spacing-top-bottom-desktop;
            padding-bottom: $spacing-top-bottom-desktop;
        }
    }
}

#main-content {
    & > .block-spacing {
        &:first-child {
            margin-top: 0;

            padding-top: calc($height-nav-primary-mobile + 32px);

            @include min(breakpoint(md)) {
                padding-top: calc($spacing-top-bottom-desktop + $height-nav-primary - 80px);
            }
        }
    }  
}

.single-content {
    .content {
        .block {
            .container {
                padding: 0;
            }
        }

        & > .block-spacing {
            padding: 0;
            margin: 32px 0;

            @include min(breakpoint(md)) {
                margin: 45px 0;
            }

            &:first-child {
                margin-top: 0;
                padding-top: 0
            }

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0
            }
        }  
    }
}

/// heading

h1,
.h1 {
    margin: 0;
    font-family: $mundial;
    font-size: rem(38px);
    font-weight: 400;
    line-height: 1;
    letter-spacing: -1.9px;

    @include min(breakpoint(md)) {
        font-size: rem(65px);
        letter-spacing: -3px;
    }
}

h2,
.h2 {
    margin: 0;
    font-family: $mundial;
    font-size: rem(32px);
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -1px;

    @include min(breakpoint(md)) {
        font-size: rem(45px);
        line-height: 1.1;
        letter-spacing: -1.35px;
    }
}

h3,
.h3 {
    margin: 0;
    font-family: $mundial;
    font-size: rem(25px);
    font-weight: 400;
    line-height: 1.2;

    @include min(breakpoint(md)) {
        font-size: rem(32px);
    }
}

h4,
.h4 {
    margin: 0;
    font-family: $mundial;
    font-size: rem(20px);
    font-weight: 400;
    line-height: 1.3;

    @include min(breakpoint(md)) {
        font-size: rem(25px);
    }
}

h5, h6, .h5, .h6 {
    margin: 0;
    font-family: $mundial;
    font-size: rem(18px);
    font-weight: 400;
    line-height: 1.4;
}

// Liens

a {
    transition: $transition;
    color: inherit;
}

// Text style

b, strong {
    font-weight: 600;
}

.fw-light {
    font-weight: 300;
}

// TAG
.tag {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 5px;
    padding: 4px 10px;
    max-width: fit-content;
    min-height: 28px;
    overflow: hidden;
    font-size: rem(12px);
    font-weight: 400;
    text-transform: uppercase;
    color: color(black);
    background-color: color(tag-beige);
    letter-spacing: 0.6px;

    &.tag-black {
        color: color(white);
        background-color: color(black);
    }
}


// Only device

.only-desktop {
    @include max(breakpoint(md)) {
        display: none !important;
    }
}

.only-mobile {
    @include min(breakpoint(md)) {
        display: none !important;
    }
}

// bg-color

.has-transparent-bg-color {
    color: inherit;
}

// Custom list
.custom-list {
    ul, ol {
        @extend %reset-ul;

        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
            align-items: flex-start;

            &::before {
                display: inline-block;
                width: 24px;
                height: 24px;
                background-image: url($path-svg + 'icon-check.svg');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                flex-shrink: 0;
                transform: translateY(6px);
                margin-right: 10px;
                content: '';
            }
        }
    }

    &.custom-list-black {
        ul, ol {
            li {
                &::before {
                    background-image: url($path-svg + 'icon-check-black.svg');
                }
            }
        }
    }
}

// Image
.wrapper-image {
    display: flex;
    transform: translateZ(0);
    overflow: hidden;

    img {
        transition: $transition;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    picture {
        width: 100%;
    }
}

// PATTERN
.pattern {
    display: flex;
    aspect-ratio: 1/1;

    svg {
        width: 100%;
        height: 100%;
    }
}

// custom fallback 
.has-fallback {
    background-color: color(secondary);
}

// swiper
.swiper-pagination {
    position: static;
    margin-top: 30px;

    .swiper-pagination-bullet {
        opacity: 1;
        background-color: color(dark-beige);
        margin: 0 6px !important;
        border-radius: 100px;
        transition: width $regular-transition, background-color $regular-transition;
    }

    .swiper-pagination-bullet-active {
        background-color: color(black);
        width: 30px;
    }

    &.swiper-pagination-white {
        .swiper-pagination-bullet {
            opacity: 1;
            background-color: rgba(255, 255, 255, 0.20);
        }
    
        .swiper-pagination-bullet-active {
            background-color: color(primary);
        }
    }
}

//GRID POSTS
.grid-posts {
    &:not(.swiper-wrapper) {
        display: grid;
        grid-gap: 40px 30px;
    
        @include min(breakpoint(md)) {
            grid-template-columns: repeat(2, 1fr);
        }
    
        @include min(breakpoint(lg)) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    
    &.swiper-wrapper-mobile {
        @include min(breakpoint(md)) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 60px;
        }
    
        @include min(breakpoint(lg)) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 60px;
        }
    }
}

