.block-contenu-2-colonnes {
    .inner-content-container {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include min(breakpoint(md)) {
            flex-direction: row;
            gap: 60px;
        }

        .column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include min(breakpoint(md)) {
                width: 50%;
            }

            .wrapper-image {
                width: 100%;
                aspect-ratio: 600/280;
                border-radius: 20px;
                margin-bottom: 30px;

                @include min(breakpoint(md)) {
                    margin-bottom: 40px;
                }
            }

            .title {
                margin-bottom: 20px;

                @include min(breakpoint(md)) {
                    margin-bottom: 30px;
                }
            }

            .text {
                flex-grow: 1;
            }

            .btn {
                margin-top: 20px;
            }
        }
    }
}
