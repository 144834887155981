.single-content {
    position: relative;

    @include min(breakpoint(md)) {
        padding-top: 60px;
    }

    .single-content-block {
        & > .container {
            & > .inner-content-container {
                @include min(breakpoint(lg)) {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    gap: 120px;
                }
        
                .aside {
                    margin-top: 50px;

                    @include min(breakpoint(lg)) {
                        flex-shrink: 0;
                        width: 300px;
                        margin: 0;
                    }
        
                    .aside-content {
                        @include min(breakpoint(lg)) {
                            position: sticky;
                            top: calc(#{$height-nav-primary} + 100px);
                        }
                    }

                    .push-aside-single {
                        background-color: color(primary);
                        padding: 70px 30px;
                        border-radius: 20px;
                        position: relative;
                        text-align: center;
                        overflow: hidden;

                        .b-to-b & {
                            background-color: color(dark-grey);
                            color: color(white);
                        }

                        .push-aside-single-title {
                            font-size: rem(25px);
                            font-weight: 400;
                            line-height: 1.2;

                            &:not(:last-child) {
                                margin-bottom: 40px;
                            }
                        }
                        .push-aside-single-title,
                        .btn {
                            position: relative;
                            z-index: 2;
                        }

                        .pattern {
                            position: absolute;

                            svg {
                                path {
                                    fill: color(white50);

                                    .b-to-b & {
                                        fill: unset;
                                        stroke: color(primary);
                                    }
                                }
                            }

                            &.first {
                                width: 595px;
                                left: -333px;
                                bottom: -446px
                            }

                            &.last {
                                width: 535px;
                                right: -290px;
                                top: -480px
                            }
                        }
                    }
                }
        
                .content {
                    max-width: 840px;
                    flex-grow: 1;
                }

                .block {
                    scroll-margin-top: calc(#{$height-nav-primary} + 30px);
                }

                .socials-share {
                    .share-networks-content {
                        margin-top: 50px;
                        padding-top: 50px;
                        border-top: 1px solid color(black20);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 24px;

                        .title {
                            margin: 0;
                        }

                        .wrapper-share-links {
                            @extend %reset-ul;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 24px;

                            @include min(breakpoint(md)) {
                                gap: 10px;
                            }

                            .share-link {
                                width: 24px;
                                height: 24px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                a {
                                    display: flex;
                                    
                                    svg {
                                        width: 100%;
                                        height: auto;

                                        @include color-svg(color(black));
                                    }

                                    &:hover {
                                        svg {
                                            @include color-svg(color(primary));
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .liste-autres-categories {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 20px;
                    margin-top: 30px;

                    .terms-list {
                        @extend %reset-ul;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 20px;

                        a {
                            &:hover {
                                color: color(grey-hover);
                            }
                        }
                    }
                }
            }
        }
    }

    .wrapper-summary {
        position: absolute;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        transition: $transition;
        top: 0;
        width: 100%;
        z-index: 999;

        &.wrapper-summary-sticky {
            position: fixed;
            
            top: calc($height-nav-primary-sticky + var(--admin-bar-height));
            background-color: color(white);
        }

        .summary {
            @extend %reset-ul;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0 40px;
            
            .summary-link {
                position: relative;
                font-size: rem(16px);
                font-weight: 400;
                line-height: 1;
                transition: color $regular-transition;
                text-decoration: none;
                display: flex;
                padding: 20px 0;

                &::after {
                    display: block;
                    width: 0;
                    height: 3px;
                    transition: width $regular-transition;
                    background-color: color(primary);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    content: '';
                }

                &:hover {
                    color: color(grey-hover);
                }

                &.active {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }
}
