.block-push-single {
    .inner-content-container {
        background-color: color(primary);
        border-radius: 20px; 
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        overflow: hidden;
        position: relative;

        .b-to-b & {
            background-color: color(dark-grey);
            color: color(white); 
        }
        
        @include min(breakpoint(lg)) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 30px 40px;
            gap: 50px;
        }

        .text {
            position: relative;
            z-index: 1;
            font-size: rem(20px);
            font-weight: 400;
            line-height: 1.2;

            @include min(breakpoint(lg)) {
                font-size: rem(25px);
            }
        }

        .wrapper-buttons {
            position: relative;
            z-index: 1;
        }

        .pattern {
            position: absolute;
            width: 570px;
            right: -300px;
            top: -140px;
            display: none;

            @include min(breakpoint(md)) {
                display: flex;
            }

            svg {
                path {
                    fill: color(white50);

                    .b-to-b & {
                        fill: unset;
                        stroke: color(primary);
                    }
                }
            }
        }
    }
}
