.block-tabs-vertical {
    overflow-x: clip;
    overflow-y: visible;

    .heading {
        margin-bottom: 30px;
        max-width: 720px;

        @include min(breakpoint(lg)) {
            margin-bottom: 50px;
        }

        .title {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        .text {
            line-height: 1.8;
        }
    }

    .wrapper-tabs {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        border-left: 1px solid rgba(0, 0, 0, .15);
        position: relative;

        @include min(breakpoint(lg)) {
            padding-left: 30px;
        }

        .tab {
            &:not(:first-child) {
                .tab-title {
                    padding-top: 30px;

                    @include min(breakpoint(md)) {
                        padding-top: 45px;
                    }
                }
            }
        }

        .tab-title {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 0;
            background-color: transparent;
            transition: $transition;
            cursor: pointer;
            border: 0;
            width: 100%;
            text-align: left;
            opacity: .3;

            @include min(breakpoint(lg)) {
                width: 50%;
                max-width: 570px;
            }

            .h3 {
                font-weight: 300;

                @include max(breakpoint(lg)) {
                    font-size: rem(25px);
                }
            }

            .tab-title-text {
                display: none;
                font-weight: 300;
            }

            &:hover,
            &.active {
                opacity: 1;
            }

            &.active {
                .tab-title-text {
                    display: block;
                }
            }
        }

        .tab-content {
            display: none;
            transition: $transition;

            @include min(breakpoint(lg)) {
                position: absolute;
                width: 50%;
                max-width: 540px;
                aspect-ratio: 540/445;
                top: 0;
                transform: translateX(50px);
                right: 0;
                display: block;
                opacity: 0;
            }

            &.active {
                display: block;

                @include min(breakpoint(lg)) {
                    opacity: 1;
                    transform: translateX(0);
                }
            }

            .wrapper-image {
                border-radius: 20px;
                aspect-ratio: 540/445;
                width: 100%;
                margin-top: 20px;

                @include min(breakpoint(lg)) {
                    margin-top: 0;
                }
            }
        }

        .tabs-progress-bar {
            height: 100px;
            width: 1px;
            position: absolute;
            top: 0;
            left: -1px;
            background-color: black;
            transition: height $regular-transition;
        }
    }

    .wrapper-buttons {
        margin-top: 35px;

        @include min(breakpoint(lg)) {
            margin-top: 50px;
        }
    }
}
