.block-formulaire {
    .inner-content-container {
        display: flex;
        flex-direction: column;
        gap: 30px;

        @include min(breakpoint(md)) {
            gap: 40px;

            &:not(.display-column) {
                flex-direction: row;
                justify-content: space-between;    
            }
        }

        .wrapper-content {
            @include min(breakpoint(md)) {
                &:not(.column) {
                    width: 50%;
                    max-width: 600px;
                }

            }

            .title {
                &:not(:last-child) {
                    margin-bottom: 30px;

                    @include min(breakpoint(md)) {
                        margin-bottom: 40px;
                    }
                }

                &.text-center {
                    text-align: center;
                }
            }
        }

        .wrapper-formulaire {
            padding: 30px 20px;
            background-color: color(primary);
            border-radius: 20px;

            @include min(breakpoint(md)) {
                width: 50%;
                max-width: 600px;
            }

            &.bg-white {
                background-color: color(white);
                box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.10);
                width: 100%;
                max-width: 1020px;
                margin: 0 auto;

                @include min(breakpoint(md)) {
                    border-radius: 30px;
                    padding: 60px;
                }
            }
        }
    }
}
