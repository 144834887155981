.block-introduction {
    .content {
        margin: 0;
        font-size: rem(16px);
        line-height: 1.6;
        font-weight: 600;

        @include min(breakpoint(md)) {
            font-size: rem(18px);
            line-height: 1.8;
        }
    }
}
