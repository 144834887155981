.tab-system-content {
    .tabs-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
        transform: translateY(-50%);
        margin: 0 auto;
        box-shadow: 0px 8px 30px rgba(0, 0, 0, .08);
        background-color: color(white);
        border-radius: 100px;
        padding: 5px 7px;
        max-width: fit-content;

        .tab-button {
            transition: $transition;
            background-color: inherit;
            border: 0;
            border-radius: inherit;
            cursor: pointer;
            padding: 5px 11px;
            font-family: $mundial;
            font-size: rem(12px);
            font-weight: 600;
            line-height: 1.2;

            @include min(breakpoint(md)) {
                padding: 18px;
                font-size: rem(14px);
            }

            @media(hover: hover) {
                &:hover {
                    background-color: color(primary);
                    color: color(white);
                }
            }

            &--active {
                background-color: color(primary);
                color: color(white);
            }
        }
    }

    .tab-content {
        display: none;

        &--active {
            display: block;
        }
    }
}
